import * as signals from "signals";
import {DeviceUtils} from "../core-utils/DeviceUtils";
import {TweenMax} from "gsap/TweenMax";

/******************************************************************
 * UIView
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class UIView<T extends HTMLElement = HTMLDivElement> {

    /******************************************************************
     * Properties
     *****************************************************************/

    protected _view: T;
    private _children: UIView<HTMLElement>[] = [];
    private _updateStylesListener: () => any;

    public onResizeSignal = new signals.Signal();
    public onAddedToStageSignal = new signals.Signal();
    public onStyleAppliedSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(protected _className: string, protected _elementName: string = "div") {
        this._initView();
        this._initListeners();
        this._checkAddedToStage();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get view(): T {
        return this._view;
    }

    get children(): UIView<HTMLElement>[] {
        return this._children;
    }

    get isStaged(): boolean {
        if (!document || !document.body) return false;
        return document.body.contains(this.view);
    }

    get interactive(): boolean {
        return this._view.style.pointerEvents != "none";
    }

    set interactive(value: boolean) {
        this._view.style.pointerEvents = value ? "auto" : "none";
        if (DeviceUtils.IS_IE) {
            if (value) {
                this._view.onclick = null;
            } else {
                this._view.onclick = (e) => {
                    e.preventDefault();
                }
            }
        }
    }

    public kill() {
        window.removeEventListener("resize", this._updateStylesListener);
    }

    public applyStyle(cssStyle: CSSStyleDeclaration | any) {
        if (cssStyle.backgroundSize) {
            this.view.style.backgroundSize = cssStyle.backgroundSize;
        }
        if (cssStyle.background) {
            this.view.style.background = cssStyle.background;
        }
        if (cssStyle.webkitOverflowScrolling) {
            this.view.style['webkitOverflowScrolling'] = cssStyle.webkitOverflowScrolling;
            delete (cssStyle.webkitOverflowScrolling);
        }
        TweenMax.set(this._view, cssStyle);
        this.onStyleAppliedSignal.dispatch();

    }

    public addChild(child: UIView<HTMLElement>) {
        if (this._children.indexOf(child) == -1) {
            this._children.push(child);
        }
        this._view.appendChild(child.view);
    }

    public addChildAt(child: UIView<HTMLElement>, at: number) {
        if (this._children.indexOf(child) != -1) {
            this._children.splice(this._children.indexOf(child), 1);
        }
        this._children.splice(at, 0, child);
        this._view.insertBefore(child.view, this.view.childNodes[at]);
    }

    public removeChild(child: UIView) {
        let childIndex = this._children.indexOf(child);
        if (childIndex != -1) {
            this._children.splice(childIndex, 1);
        }
        if (child.view.parentElement) {
            child.view.parentElement.removeChild(child.view);
        }
    }

    public removeAllChildren() {
        this._view.innerHTML = null;
        this._children = [];
    }

    public updateStyles() {
        // override this
        // updateStyles is called automatically on window.resize and after view added to stage
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private _initView() {
        this._view = document.createElement(this._elementName) as T;
        this._view.className = this._className;
    }

    private _initListeners() {
        this._updateStylesListener = () => {
            this.updateStyles();
            this.onResizeSignal.dispatch();
        };
        window.addEventListener("resize", this._updateStylesListener);
        this.onAddedToStageSignal.add(this._updateStylesListener);
    }

    private _checkAddedToStage() {
        if (this.isStaged) {
            this.onAddedToStageSignal.dispatch();
        } else {
            requestAnimationFrame(() => this._checkAddedToStage());
        }
    }

    /******************************************************************
     * Events
     *****************************************************************/

}
