/******************************************************************
 * GSAPUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/


export class GSAPUtils {

    static getTransformX(element: HTMLElement): number {
        if (!element) return 0;
        let gsTransform = element['_gsTransform'];
        if (gsTransform && gsTransform.x) return gsTransform.x;
        return 0;
    }

    static getTransformY(element: HTMLElement): number {
        if (!element) return 0;
        let gsTransform = element['_gsTransform'];
        if (gsTransform && gsTransform.y) return gsTransform.y;
        return 0;
    }
}