import {UIView} from "../../../../../@jashson/core-ui/UIView";
import {Power2, TweenMax} from "gsap";
import {AppBackgroundCircles} from "./AppBackgroundCircles";

/******************************************************************
 * AppBackground
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AppBackground extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _circles: AppBackgroundCircles;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("app-background");
        this.initBackgroundImage();
        this.onAddedToStageSignal.addOnce(() => this.playIntro());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public showCircles() {
        this.initCircles();
        this.updateStyles();
    }

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            width: "100vw",
            height: "100vh"
        });
        if (this._circles) {
            this._circles.applyStyle({
                position: "absolute"
            });
        }
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initBackgroundImage() {
        this.applyStyle({
            backgroundImage: "url(" + require("ASSETS/images/background-pattern.png") + ")",
            alpha: 0
        })
    }

    private initCircles() {
        this._circles = new AppBackgroundCircles();
        this.addChild(this._circles);
    }

    private playIntro() {
        TweenMax.to(this.view, 1, {
            alpha: 1,
            ease: Power2.easeInOut
        });
    }

    /******************************************************************
     * Events
     *****************************************************************/


}
