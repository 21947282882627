import {MenuItemModel} from "./menuitemgroup/MenuItemModel";

/******************************************************************
 * MenuModel
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class MenuModel {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _categoryGroupItems: MenuItemModel[] = [];
    private _personalGroupItems: MenuItemModel[] = [];

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _jsonVO: any) {
        this.initPageItems();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get categoryGroupItems(): MenuItemModel[] {
        return this._categoryGroupItems;
    }

    get personalGroupItems(): MenuItemModel[] {
        return this._personalGroupItems;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/


    /******************************************************************
     * Events
     *****************************************************************/

    private initPageItems() {
        this._jsonVO.pages.forEach((categoryVO: any) => {
            let menuItemModel = new MenuItemModel(categoryVO);
            if (menuItemModel.isPersonal) {
                this._personalGroupItems.push(menuItemModel);
            } else {
                this._categoryGroupItems.push(menuItemModel);
            }
        })
    }
}
