import {UIView} from "../../../../../@jashson/core-ui/UIView";
import {SVGView} from "../../../../../@jashson/core-svg/SVGView";
import {Power4, TweenMax} from "gsap";
import {SitemapModel} from "../../../../model/SitemapModel";
import {PageModel} from "../pages/PageModel";
import {FocusMapPoint} from "./FocusMapPoint";
import {SizeAndPosition} from "../../../../style/SizeAndPosition";
import {AppConfig} from "../../../../config/AppConfig";

/******************************************************************
 * FocusMap
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FocusMap extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _frameSVG: SVGView;
    private _frameCornerBL: SVGElement;
    private _frameCornerTR: SVGElement;
    private _frameCornerBR: SVGElement;
    private _frameCornerTL: SVGElement;
    private _focusPointsBox: UIView<HTMLDivElement>;
    private _pointerSVG: SVGView;

    private static readonly CORNER_SIZE = 8;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _sitemapModel: SitemapModel) {
        super("focus-map");
        this.initFrameSVG();
        this.initFocusPoints();
        this.initFocusMapPointer();
        this.onAddedToStageSignal.addOnce(() => {
            requestAnimationFrame(() => {
                this.updateStyles();
            })
        })
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public playIntro() {
        TweenMax.set(this.view, {
            autoAlpha: 0,
            y: "300%",
        });
        TweenMax.to(this.view, 1, {
            delay: AppConfig.SHOW_UI_DELAY,
            y: "0%",
            autoAlpha: SizeAndPosition.IS_SMALL_SCREEN ? 0 : 1,
            ease: Power4.easeOut
        })
    }

    public show() {
        TweenMax.to(this.view, AppConfig.META_LAYER_DURATION, {
            y: "0%",
            autoAlpha: SizeAndPosition.IS_SMALL_SCREEN ? 0 : 1,
            ease: Power4.easeOut
        })
    }

    public hide() {
        TweenMax.to(this.view, AppConfig.META_LAYER_DURATION, {
            y: "200%",
            autoAlpha: 0,
            ease: Power4.easeIn
        })
    }

    public setPointer(pageID: number, pageSlideID: number) {
        let space = 5;
        if (pageID == undefined) {
            pageID = -1;
        }
        if (!pageSlideID) {
            pageSlideID = 0;
        }
        TweenMax.to(this._pointerSVG.view, AppConfig.CHANGE_PAGE_DURATION, {
            x: space * pageSlideID - 1,
            y: space * (pageID + 1),
            ease: Power4.easeOut
        })
    }

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            bottom: SizeAndPosition.APP_UI_MARGIN,
            left: 20,
            autoAlpha: SizeAndPosition.IS_SMALL_SCREEN ? 0 : 1
        });
        this._focusPointsBox.applyStyle({
            position: "absolute",
            top: FocusMap.CORNER_SIZE,
            left: FocusMap.CORNER_SIZE
        });
        this._pointerSVG.applyStyle({
            position: "absolute",
            fontSize: 0,
            top: FocusMap.CORNER_SIZE + 1,
            left: FocusMap.CORNER_SIZE + 1
        });
        this.updateFocusPoints();
        this.updateFrameSize()
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initFrameSVG() {
        this._frameSVG = new SVGView(require("ASSETS/images/focusmap-frame.svg"));
        this._frameCornerTL = this._frameSVG.getElementByID("tl");
        this._frameCornerBL = this._frameSVG.getElementByID("bl");
        this._frameCornerTR = this._frameSVG.getElementByID("tr");
        this._frameCornerBR = this._frameSVG.getElementByID("br");
        this.addChild(this._frameSVG);
    }

    private initFocusPoints() {
        this._focusPointsBox = new UIView("focus-points");
        this.addChild(this._focusPointsBox);
        let hompage = this.createFocusPointLine(1);
        this._focusPointsBox.addChild(hompage);
        this._sitemapModel.pageModels.forEach((pageModel: PageModel) => {
            let numSlides = pageModel.slideModels.length;
            if (numSlides == 0) {
                numSlides = 1;
            }
            this._focusPointsBox.addChild(this.createFocusPointLine(numSlides));
        });
    }

    private initFocusMapPointer() {
        this._pointerSVG = new SVGView(require("ASSETS/images/focusmap-pointer.svg"));
        this.addChild(this._pointerSVG);
    }

    private updateFrameSize() {
        let width = this._focusPointsBox.view.getBoundingClientRect().width + FocusMap.CORNER_SIZE * 2;
        let height = this._focusPointsBox.view.getBoundingClientRect().height + FocusMap.CORNER_SIZE * 2;
        if (width < height * 3 / 2) {
            width = height * 3 / 2;
        }
        TweenMax.set(this._frameCornerTR, {
            x: width - FocusMap.CORNER_SIZE - 1
        });
        TweenMax.set(this._frameCornerBL, {
            y: height - FocusMap.CORNER_SIZE - 3
        });
        TweenMax.set(this._frameCornerBR, {
            x: width - FocusMap.CORNER_SIZE - 1,
            y: height - FocusMap.CORNER_SIZE - 3
        });
        this._frameSVG.width = width;
        this._frameSVG.height = height;
    }

    private createFocusPointLine(numPoints: number): UIView {
        let lineBox = new UIView("line");
        for (let i = 0; i < numPoints; i++) {
            lineBox.addChild(new FocusMapPoint());
        }
        return lineBox;
    }

    private updateFocusPoints() {
        this._focusPointsBox.children.forEach((line: SVGView) => {
            line.applyStyle({
                whiteSpace: "nowrap",
                fontSize: 0
            });
            line.children.forEach((point: FocusMapPoint) => {
                point.applyStyle({
                    display: "inline-block",
                    top: 0,
                    left: 0
                })
            });
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

}
