/******************************************************************
 * URLUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class URLUtils {

    static openLink(href: string, openAsPopup: boolean = false, openInNewWindow: boolean = true) {
        if (href.indexOf("mailto") != -1) {
            location.href = href;
        } else if (href.indexOf("whatsapp") != -1) {
            location.href = href;
        } else if (openAsPopup) {
            window.open(href, '', 'width=600, height=300, menubar=no, toolbar=no, resizable=yes, scrollbars=yes').focus();
        } else {
            window.open(href, openInNewWindow ? '_blank' : '_self').focus();
        }
    }

    static downloadLink(href: string) {
        let anchorElement = document.createElement("a");
        anchorElement.href = href;
        anchorElement.download = "download";
        anchorElement.click();
    }
}
