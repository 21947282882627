import {UIView} from "../../../../@jashson/core-ui/UIView";
import {FontStyle} from "../../../style/FontStyle";
import {Color} from "../../../style/Color";
import {SizeAndPosition} from "../../../style/SizeAndPosition";

/******************************************************************
 * HeaderTitle
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class HeaderTitle extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/


    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _title: string) {
        super("header-title");
        this.view.innerHTML = this._title;
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            textTransform: "uppercase",
            left: "50%",
            top: SizeAndPosition.HEADER_HEIGHT,
            y: "-100%",
            x: "-50%",
            whiteSpace: "nowrap",
            fontSize: FontStyle.FONT_SIZE_HEADER_TITLE,
            fontWeight: FontStyle.FONT_WEIGHT_MEDIUM,
            paddingBottom: FontStyle.FONT_SIZE_HEADER_TITLE * (SizeAndPosition.IS_SMALL_SCREEN ? 0.5 : 1),
            color: Color.GREY
        });
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/


    /******************************************************************
     * Events
     *****************************************************************/

}
