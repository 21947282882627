import {GrottkerWebsiteMain} from "./grottker-website/GrottkerWebsiteMain";
require("../scss/index.scss");
require("../libs/gsap/DrawSVGPlugin.js");

/* ----------------------------------------------------------------
 * Version
 * --------------------------------------------------------------*/

console.log("Katharina Grottker Website |", VERSION);

/* ----------------------------------------------------------------
 * Initialization
 * --------------------------------------------------------------*/

window.onload = () => {
    let appShell = new GrottkerWebsiteMain();
    document.body.appendChild(appShell.view);
};
