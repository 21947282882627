import {PageSlideType} from "../../PageSlideType";
import {ILinkVO} from "../../../../../../vo/ILinkVO";
import {UIView} from "../../../../../../../@jashson/core-ui/UIView";
import {Color} from "../../../../../../style/Color";
import {FontStyle} from "../../../../../../style/FontStyle";
import {ElementStyle} from "../../../../../../style/ElementStyle";
import {ShadowStyle} from "../../../../../../style/ShadowStyle";
import {LinkButtons} from "./linkbuttons/LinkButtons";
import {HeaderTitle} from "../../../../basic/HeaderTitle";
import {TweenMax} from "gsap";
import {AppConfig} from "../../../../../../config/AppConfig";
import {AbstractPageSlideScrollableContent} from "../AbstractPageSlideScrollableContent";
import {SizeAndPosition} from "../../../../../../style/SizeAndPosition";

/******************************************************************
 * Contact
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Contact extends AbstractPageSlideScrollableContent {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _descField: UIView<HTMLDivElement>;
    private _linkButtons: LinkButtons;
    private _headerTitle: HeaderTitle;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _title: string, private _desc: string, private _links: ILinkVO[]) {
        super(PageSlideType.CONTACT);
        this.initDescField();
        this.initLinks();
        this.initHeaderTitle();
        this.applyStyle({
            visibility: "hidden"
        })
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.interactive = true;
        this._contentBox.applyStyle({
            position: "absolute",
            top: "50%",
            left: "50%",
            x: "-50%",
            y: "-50%"
        });
        this._descField.applyStyle({
            color: Color.GREY,
            textAlign: "center",
            fontSize: FontStyle.FONT_SIZE_CONTENT_COPY,
            lineHeight: FontStyle.FONT_SIZE_CONTENT_COPY * 1.4 + "px",
            textShadow: AppConfig.SHOW_TEXT_SHADOWS ? ShadowStyle.STANDARD_SHADOW : "none"
        });
        ElementStyle.applyContentCopyAnchorStyle(Array.from(this._descField.view.getElementsByTagName("a")));
        this._linkButtons.applyStyle({
            marginTop: FontStyle.FONT_SIZE_CONTENT_COPY * 2,
            textAlign: "center"
        });
        this._headerTitle.applyStyle({
            display: SizeAndPosition.USE_LANDSCAPE_MODE ? "none" : "block"
        });
        super.updateStyles();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initDescField() {
        this._descField = new UIView("descfield");
        this._descField.view.innerHTML = this._desc;
        this._contentBox.addChild(this._descField);
    }

    private initLinks() {
        this._linkButtons = new LinkButtons(this._links);
        this._contentBox.addChild(this._linkButtons);
    }

    private initHeaderTitle() {
        this._headerTitle = new HeaderTitle(this._title);
        this.addChild(this._headerTitle);
    }

    protected updatePositionProgress() {
        TweenMax.set(this._headerTitle.view, {
            autoAlpha: 1 - Math.abs(this._verticalProgress)
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/



}
