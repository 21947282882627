import {UIView} from "../../../../../@jashson/core-ui/UIView";
import {SVGView} from "../../../../../@jashson/core-svg/SVGView";

/******************************************************************
 * FocusMapPoint
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FocusMapPoint extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _pointSVG: SVGView;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("focus-point");
        this.initPointSVG();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            fontSize: 0,
            padding: 1
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initPointSVG() {
        this._pointSVG = new SVGView(require("ASSETS/images/focusmap-point.svg"));
        this.addChild(this._pointSVG);
    }

    /******************************************************************
     * Events
     *****************************************************************/


}
