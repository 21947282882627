import {UIView} from "../../../../../@jashson/core-ui/UIView";
import {MenuModel} from "./MenuModel";
import {MenuLogo} from "./logo/MenuLogo";
import {SizeAndPosition} from "../../../../style/SizeAndPosition";
import {MenuItemGroup} from "./menuitemgroup/MenuItemGroup";
import {FontStyle} from "../../../../style/FontStyle";
import {Power4, TweenMax} from "gsap";
import {DOMUtils} from "../../../../../@jashson/core-utils/DOMUtils";
import {AppConfig} from "../../../../config/AppConfig";
import * as signals from "signals";
import {GenericTextModel} from "../../../../model/GenericTextModel";
import {Color} from "../../../../style/Color";

/******************************************************************
 * Menu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Menu extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _logo: MenuLogo;
    private _categoryItemGroup: MenuItemGroup;
    private _personalItemGroup: MenuItemGroup;
    private _groupBox: UIView;
    private _isShowingMenu = false;
    private _hitzone: UIView;
    private _logoCenterHelper: UIView;
    private _descField: UIView;
    private _userIsOverHitzone: boolean = false;
    private _topMode: boolean = false;
    private _onShowingItemsComplete: boolean;
    private _hasShowingMenuFirstTimeWhenCentered = false;

    public onItemClickSignal = new signals.Signal();
    public onShowMenuSignal = new signals.Signal();
    public onHideMenuSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _model: MenuModel) {
        super("menu");
        this.initHitzone();
        this.initLogo();
        this.initGroupBox();
        this.initCategoriesItems();
        this.initPersonalItems();
        this.initLogoCenterHelper();
        this.initDescField();
        this.interactive = false;
        this.applyStyle({
            display: "none"
        });
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public get useVerticalMenu(): boolean {
        return SizeAndPosition.STAGE_WIDTH < 1180;
    }

    public show() {
        this.interactive = true;
        TweenMax.to(this.view, AppConfig.META_LAYER_DURATION, {
            autoAlpha: this.calcAlpha()
        })
    }

    public hide() {
        this.interactive = false;
        TweenMax.to(this.view, AppConfig.META_LAYER_DURATION, {
            autoAlpha: 0
        })
    }

    public moveToTop() {
        this.killAutoShowMenu();
        this.interactive = false;
        this._isShowingMenu = false;
        this._topMode = true;
        this._logo.showAsMenuLogo(true);
        this.hideItems();
        this.showDescField(false);
        this.tweenLogoToTargetPosition(AppConfig.CHANGE_PAGE_DURATION);
        this.tweenGroupBoxToTargetPosition(AppConfig.CHANGE_PAGE_DURATION);
        this.tweenHitzoneToTargetPosition(AppConfig.CHANGE_PAGE_DURATION);
        if (SizeAndPosition.USE_LANDSCAPE_MODE) {
            this.hide();
        }
        TweenMax.delayedCall(AppConfig.CHANGE_PAGE_DURATION, () => {
            this.interactive = true;
        });
        this.onHideMenuSignal.dispatch();
    }

    public moveToCenter(showMenu: boolean = false) {
        this.interactive = false;
        this._topMode = false;
        this._isShowingMenu = false;
        this.show();
        TweenMax.delayedCall(AppConfig.CHANGE_PAGE_DURATION, () => {
            this.interactive = true;
        });
        if (showMenu) {
            this.showMenu();
        } else {
            this._logo.showAsFullLogo();
            this.hideItems();
            this.autoShowMenu();
            this.onHideMenuSignal.dispatch();
        }
        this.tweenLogoToTargetPosition(AppConfig.CHANGE_PAGE_DURATION);
        this.tweenGroupBoxToTargetPosition(AppConfig.CHANGE_PAGE_DURATION);
        this.tweenHitzoneToTargetPosition(AppConfig.CHANGE_PAGE_DURATION);
    }

    public showLogo() {
        if (!this._isShowingMenu) return;
        this._isShowingMenu = false;
        if (this._topMode) {
            this._logo.showAsMenuLogo(true);
        } else {
            this._logo.showAsFullLogo();
        }
        this.hideItems();
        this.showDescField(false);
        this.onHideMenuSignal.dispatch();
    }

    public showMenu() {
        if (this._isShowingMenu) return;
        this._isShowingMenu = true;
        this.updateLogoSVGOffset();
        this._logo.showAsMenuLogo();
        this.showItems();
        this.showDescField(true);
        this.onShowMenuSignal.dispatch();
    }

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            top: 0,
            left: 0,
            autoAlpha: this.calcAlpha(),
            display: "block"
        });
        this._hitzone.applyStyle({
            position: "absolute"
        });
        this.tweenHitzoneToTargetPosition(0);
        this._logo.applyStyle({
            position: "absolute",
            cursor: "pointer"
        });
        this.tweenLogoToTargetPosition(0);
        this._groupBox.applyStyle({
            position: "absolute",
            whiteSpace: "nowrap",
        });
        this.tweenGroupBoxToTargetPosition(0);
        this._categoryItemGroup.useVerticalMenu = this.useVerticalMenu;
        this._categoryItemGroup.applyStyle({
            display: this.useVerticalMenu ? "block" : "inline-block",
            width: this.useVerticalMenu ? "100%" : this._categoryItemGroup.groupWidth,
            marginRight: this.useVerticalMenu ? 0 : this.calcLogoSpaceX()
        });
        this._personalItemGroup.useVerticalMenu = this.useVerticalMenu;
        this._personalItemGroup.applyStyle({
            display: this.useVerticalMenu ? "block" : "inline-block",
            width: this.useVerticalMenu ? "100%" : this._personalItemGroup.groupWidth,
            marginTop: this.useVerticalMenu ? this.calcLogoSpaceY() : 0
        });
        this._logoCenterHelper.applyStyle({
            position: "absolute",
            autoAlpha: 0,
            width: 3,
            height: 3,
            backgroundColor: "red",
            left: this.calcLogoCenterX(),
            top: this.calcLogoCenterY()
        });
        this._descField.applyStyle({
            position: "absolute",
            display: this.useVerticalMenu ? "none" : "block",
            width: SizeAndPosition.STAGE_WIDTH - SizeAndPosition.APP_UI_MARGIN * 2,
            maxWidth: 600 + "px",
            left: SizeAndPosition.STAGE_WIDTH * 0.5,
            top: SizeAndPosition.STAGE_HEIGHT * 0.65,
            x: "-50%",
            y: "-10%",
            color: Color.convertHEXtoRGBAString(Color.WHITE, 0.6),
            fontSize: FontStyle.FONT_SIZE_META_COPY + "px",
            lineHeight: FontStyle.FONT_SIZE_META_COPY * 1.5 + "px",
            textAlign: "center"
        })
        this.updateLogoSVGOffset();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initHitzone() {
        this._hitzone = new UIView("hitzone");
        this.addChild(this._hitzone);
    }

    private initLogo() {
        this._logo = new MenuLogo();
        this._logo.onIntroCompleteSignal.add(() => this.onLogoIntroCompleted());
        this._logo.playIntro();
        this._logo.view.addEventListener("click", () => this.onLogoClicked());
        this.addChild(this._logo);
    }

    private initGroupBox() {
        this._groupBox = new UIView("menu-item-groups");
        this._groupBox.interactive = false;
        this.addChild(this._groupBox);
    }

    private initCategoriesItems() {
        this._categoryItemGroup = new MenuItemGroup(this._model.categoryGroupItems);
        this._categoryItemGroup.onGroupWidthChangeSignal.add(() => this.updateStyles());
        this._categoryItemGroup.onItemClickSignal.add((deeplink: string) => this.onItemClicked(deeplink));
        this._groupBox.addChild(this._categoryItemGroup);
    }

    private initPersonalItems() {
        this._personalItemGroup = new MenuItemGroup(this._model.personalGroupItems);
        this._personalItemGroup.onGroupWidthChangeSignal.add(() => this.updateStyles());
        this._personalItemGroup.onItemClickSignal.add((deeplink: string) => this.onItemClicked(deeplink));
        this._groupBox.addChild(this._personalItemGroup);
    }

    private initDescField() {
        this._descField = new UIView("desc-field");
        this._descField.view.innerHTML = GenericTextModel.SITE_DESC;
        this._descField.applyStyle({
            autoAlpha: 0
        })
        this.addChild(this._descField);
    }

    private initListeners() {
        window.addEventListener("mousemove", (event: MouseEvent) => this.onMouseMove(event))
    }

    private initLogoCenterHelper() {
        this._logoCenterHelper = new UIView("logo-center-helper");
        this._logoCenterHelper.interactive = false;
        this.addChild(this._logoCenterHelper);
    }

    private showDescField(value: boolean, instantly: boolean = false) {
        TweenMax.to(this._descField.view, instantly ? 0 : 0.5, {
            display: this.useVerticalMenu ? "none" : "block",
            delay: value ? 1 : 0,
            autoAlpha: value && !this._topMode ? 1 : 0
        })
    }

    private calcLogoSpaceX(): number {
        return FontStyle.FONT_SIZE_MENU_ITEM * 3;
    }

    private calcLogoSpaceY(): number {
        return FontStyle.FONT_SIZE_MENU_ITEM * 3;
    }

    private showItems() {
        TweenMax.killTweensOf(this._groupBox.view);
        TweenMax.to(this._groupBox.view, AppConfig.CHANGE_PAGE_DURATION, {
            delay: AppConfig.CHANGE_PAGE_DURATION * 0.5,
            autoAlpha: 1,
            onComplete: () => {
                this._onShowingItemsComplete = true;
            }
        })
    }

    private hideItems() {
        TweenMax.delayedCall(0.1, () => {
            this._onShowingItemsComplete = false;
        });
        TweenMax.killTweensOf(this._groupBox.view);
        TweenMax.to(this._groupBox.view, AppConfig.CHANGE_PAGE_DURATION * 0.5, {
            autoAlpha: 0
        })
    }

    private killAutoShowMenu() {
        TweenMax.killDelayedCallsTo(this.showMenu);
    }

    private autoShowMenu() {
        if (AppConfig.AUTO_SHOW_MENU_WHEN_CENTERED) {
            let delay = AppConfig.AUTO_SHOW_MENU_WHEN_CENTERED_FIRST_DURATION;
            if (this._hasShowingMenuFirstTimeWhenCentered) {
                delay = AppConfig.AUTO_SHOW_MENU_WHEN_CENTERED_DURATION
            }
            TweenMax.delayedCall(delay, this.showMenu, null, this);
            if (!this._hasShowingMenuFirstTimeWhenCentered) {
                this._hasShowingMenuFirstTimeWhenCentered = true;
            }
        }
    }

    private tweenLogoToTargetPosition(duration: number) {
        let top = SizeAndPosition.STAGE_HEIGHT * 0.5;
        let left = SizeAndPosition.STAGE_WIDTH * 0.5;
        let logoPaddingOffsetX = this.useVerticalMenu ? 0 : -16.5;
        let centerOffsetX = this.useVerticalMenu ? -50 : 0;
        let x = centerOffsetX + logoPaddingOffsetX + "%";
        let y = "-50%";
        if (this._topMode) {
            top = 0;
            y = "-20%";
        }
        TweenMax.to(this._logo.view, duration, {
            top: top,
            left: left,
            x: x,
            y: y,
            ease: Power4.easeInOut
        });
    }

    private tweenGroupBoxToTargetPosition(duration: number) {
        let top = SizeAndPosition.STAGE_HEIGHT * 0.5;
        let left = SizeAndPosition.STAGE_WIDTH * 0.5;
        let x = "-50%";
        let y = "-50%";
        if (this._topMode && !this.useVerticalMenu) {
            top = 30;
            y = "0%";
        }
        TweenMax.to(this._groupBox.view, duration, {
            top: top,
            left: left,
            x: x,
            y: y,
            autoAlpha: this._isShowingMenu ? 1 : 0,
            ease: Power4.easeInOut
        });
    }

    private tweenHitzoneToTargetPosition(duration: number) {
        let width = SizeAndPosition.STAGE_WIDTH;
        let height = 200;
        let top = SizeAndPosition.STAGE_HEIGHT * 0.5;
        let left = SizeAndPosition.STAGE_WIDTH * 0.5;
        let x = "-50%";
        let y = "-50%";
        if (this._topMode) {
            top = 0;
            y = "0%";
            height = 90;
        }
        TweenMax.to(this._hitzone.view, duration, {
            width: width,
            height: height,
            top: top,
            left: left,
            x: x,
            y: y,
            ease: Power4.easeInOut
        });
    }

    private calcLogoCenterX() {
        let catGroupRect = this._categoryItemGroup.view.getBoundingClientRect();
        if (this.useVerticalMenu) {
            return catGroupRect.left + catGroupRect.width * 0.5;
        } else {
            return catGroupRect.left + catGroupRect.width + this.calcLogoSpaceX() * 0.5;
        }
    }

    private calcLogoCenterY() {
        let catGroupRect = this._categoryItemGroup.view.getBoundingClientRect();
        if (this.useVerticalMenu) {
            return catGroupRect.top + catGroupRect.height + this.calcLogoSpaceY() * 0.5;
        } else {
            return catGroupRect.top + catGroupRect.height * 0.5;
        }
    }

    private calcLogoSVGOffsetX() {
        return this.calcLogoCenterX() - this._logo.circleCenterClientRect.left;
    }

    private calcLogoSVGOffsetY() {
        return this.calcLogoCenterY() - this._logo.circleCenterClientRect.top;
    }

    private calcAlpha() {
        return SizeAndPosition.USE_LANDSCAPE_MODE && this._topMode ? 0 : 1;
    }

    private updateLogoSVGOffset() {
        this._logo.useVerticalMenu = this.useVerticalMenu;
        this._logo.svgOffsetX = 0; // need to be reset for correct calculations of getBoundingClientRect
        this._logo.svgOffsetY = 0; // need to be reset for correct calculations of getBoundingClientRect
        this._logo.svgOffsetX = this.calcLogoSVGOffsetX();
        this._logo.svgOffsetY = this.calcLogoSVGOffsetY();
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onLogoIntroCompleted() {
        if (!TweenMax.isTweening(this._groupBox.view)) {
            this.interactive = true;
        }
        this.initListeners();
    }

    private onMouseMove(event: MouseEvent) {
        if (!this.interactive || TweenMax.isTweening(this._groupBox.view || this.useVerticalMenu)) return;
        this._userIsOverHitzone = DOMUtils.isMouseInRect(this._hitzone.view.getBoundingClientRect(), event.clientX, event.clientY);
        if (!this._topMode && AppConfig.AUTO_SHOW_MENU_WHEN_CENTERED) return;
        if (this._userIsOverHitzone) {
            this.showMenu();
        } else {
            this.showLogo();
        }
    }

    private onItemClicked(deeplink: string) {
        this.onItemClickSignal.dispatch(deeplink);
    }

    private onLogoClicked() {
        if (!this.interactive || !this._onShowingItemsComplete) return;
        if (this.useVerticalMenu && this._topMode) {
            this.showLogo();
        } else {
            this.onItemClickSignal.dispatch("");
        }
    }

}
