import {UIView} from "../../../../../@jashson/core-ui/UIView";
import {PageSlideModel} from "./PageSlideModel";
import {PageSlideType} from "./PageSlideType";
import {CategoryInfo} from "./content/categoryinfo/CategoryInfo";
import {AbstractPageSlideContent} from "./content/AbstractPageSlideContent";
import {SizeAndPosition} from "../../../../style/SizeAndPosition";
import {PhotoStory} from "./content/photostory/PhotoStory";
import * as signals from "signals";
import {About} from "./content/about/About";
import {Contact} from "./content/contact/Contact";
import {IRouteVO} from "../../../../vo/IRouteVO";
import {ShowMenu} from "./content/showmenu/ShowMenu";
import {Studio} from "./content/studio/Studio";

/******************************************************************
 * PageSlide
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PageSlide extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _content: AbstractPageSlideContent;

    public onNextSlideSignal = new signals.Signal();
    public onPrevSlideSignal = new signals.Signal();
    public onContentChangeSignal = new signals.Signal();
    public onShowMenuSignal = new signals.Signal();
    public onHideMenuSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _model: PageSlideModel, private _isFirstSlide: boolean = false, private _intendedSlideID = undefined) {
        super("page-slide");
        this.initSlideContent();
        this.interactive = false;
        this.applyStyle({
            visibility: "hidden",
        });
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get intendedSlideID(): number {
        return this._intendedSlideID;
    }

    get deeplink(): string {
        return this._model.deeplink;
    }

    get contentDeeplinks(): string[] {
        return this._model.contentDeeplinks;
    }

    get horizontalProgress(): number {
        return this._content.horizontalProgress;
    }

    set horizontalProgress(value: number) {
        this._content.horizontalProgress = value;
    }

    get verticalProgress(): number {
        return this._content.verticalProgress;
    }

    set verticalProgress(value: number) {
        this._content.verticalProgress = value;
    }

    get hasNextContent(): boolean {
        return this._content.hasNextContent;
    }

    get hasPrevContent(): boolean {
        return this._content.hasPrevContent;
    }

    public prepareSlide() {
        this._content.prepareContent();
    }

    public getNextContentRoute(): IRouteVO {
        return this._content.getNextContentRoute();
    }

    public getPrevContentRoute(): IRouteVO {
        return this._content.getPrevContentRoute();
    }

    public showContent(contentID: number) {
        this._content.showContent(contentID);
    }

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            top: 0,
            left: 0,
            width: SizeAndPosition.STAGE_WIDTH,
            height: SizeAndPosition.STAGE_HEIGHT,
            visibility: "visible"
        });
        this._content.updateStyles();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initSlideContent() {
        switch (this._model.type) {
            case PageSlideType.CATEGORY_INFO:
                this._content = new CategoryInfo(this._model.data.title, this._model.data.subTitle, this._model.data.desc, this._model.data.buttonLabel);
                break;
            case PageSlideType.PHOTO_STORY:
                this._content = new PhotoStory(this._model.data, this._isFirstSlide, this._model.contentDeeplinks);
                break;
            case PageSlideType.ABOUT_ME:
                this._content = new About(this._model.data.title, this._model.data.desc, this._model.data.portraitURL, this._model.data.buttonLabel);
                break;
            case PageSlideType.STUDIO:
                this._content = new Studio(this._model.data.title, this._model.data.desc, this._model.data.studioURL, this._model.data.buttonLabel);
                break;
            case PageSlideType.CONTACT:
                this._content = new Contact(this._model.data.title, this._model.data.desc, this._model.data.links);
                break;
            case PageSlideType.SHOW_MENU:
                this._content = new ShowMenu();
                break;
            default:
                this._content = new AbstractPageSlideContent(this._model.type, this._isFirstSlide);
                console.log("WARNING: NO PAGE_SLIDE_CONTENT defined for:", this._model.type)
        }
        this._content.onNextSlideSignal.add(() => this.onNextSlideSignal.dispatch());
        this._content.onPrevSlideSignal.add(() => this.onPrevSlideSignal.dispatch());
        this._content.onContentChangeSignal.add((deeplink) => this.onContentChangeSignal.dispatch(deeplink));
        this._content.onShowMenuSignal.add(() => this.onShowMenuSignal.dispatch());
        this._content.onHideMenuSignal.add(() => this.onHideMenuSignal.dispatch());
        this.addChild(this._content);
    }

    /******************************************************************
     * Events
     *****************************************************************/

}
