import {AbstractPageSlideContent} from "../AbstractPageSlideContent";
import {UIView} from "../../../../../../../@jashson/core-ui/UIView";
import {Color} from "../../../../../../style/Color";
import {FontStyle} from "../../../../../../style/FontStyle";
import {TweenMax} from "gsap";
import {HeaderTitle} from "../../../../basic/HeaderTitle";
import {SizeAndPosition} from "../../../../../../style/SizeAndPosition";
import {LabelButton} from "../../../../basic/LabelButton";
import {AppConfig} from "../../../../../../config/AppConfig";

/******************************************************************
 * CategoryInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CategoryInfo extends AbstractPageSlideContent {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _titleField: UIView<HTMLDivElement>;
    private _subTitleField: UIView<HTMLDivElement>;
    private _descField: UIView<HTMLDivElement>;
    private _headerTitle: HeaderTitle;
    private _contentBox: UIView<HTMLDivElement>;
    private _showImagesButton: LabelButton;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _title: string, private _subTitle: string, private _desc: string, private _buttonLabel: string) {
        super("category-info");
        this.initContentBox();
        this.initTitle();
        this.initSubTitle();
        this.initHeaderTitle();
        this.initDesc();
        this.initShowImagesButton();
        this.applyStyle({
            visibility: "hidden"
        });
        this.onHorizontalCenterLeaveSignal.add(() => {
            this._showImagesButton.interactive = false;
        });
        this.onHorizontalCenterReachSignal.add(() => {
            this._showImagesButton.interactive = true;
        });
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            position: "relative",
            width: SizeAndPosition.STAGE_WIDTH,
            height: SizeAndPosition.STAGE_HEIGHT,
            userSelect: "none"
        });
        this._contentBox.applyStyle({
            position: "absolute",
            width: SizeAndPosition.USE_LANDSCAPE_MODE ? "50%" : "70%",
            maxWidth: SizeAndPosition.MAX_TEXT_WIDTH + "px",
            top: "50%",
            left: "50%",
            x: "-50%",
            y: "-50%",
            textAlign: "center"
        });
        this._titleField.applyStyle({
            textTransform: "uppercase",
        });
        this._subTitleField.applyStyle({
            textTransform: "uppercase",
        });
        this._descField.applyStyle({
            marginTop: FontStyle.FONT_SIZE_CONTENT_COPY,
            fontSize: FontStyle.FONT_SIZE_CONTENT_COPY,
            textAlign: "left"
        });
        this._showImagesButton.applyStyle({
            marginTop: FontStyle.FONT_SIZE_CONTENT_COPY,
        });
        this.scaleToWidth(this._titleField);
        this.scaleToWidth(this._subTitleField);
        this.updatePositionProgress();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initContentBox() {
        this._contentBox = new UIView("content-box");
        this.addChild(this._contentBox);
    }

    private initTitle() {
        this._titleField = new UIView("title");
        this._titleField.view.innerHTML = this._title;
        this._contentBox.addChild(this._titleField);
    }

    private initSubTitle() {
        this._subTitleField = new UIView("sub-title");
        this._subTitleField.view.innerHTML = this._subTitle;
        this._contentBox.addChild(this._subTitleField);
    }

    private initDesc() {
        this._descField = new UIView("sub-title");
        this._descField.view.innerHTML = this._desc;
        this._contentBox.addChild(this._descField);
    }

    private initHeaderTitle() {
        this._headerTitle = new HeaderTitle(this._title + " " + this._subTitle);
        this.addChild(this._headerTitle);
    }

    private initShowImagesButton() {
        this._showImagesButton = new LabelButton(this._buttonLabel);
        this._showImagesButton.showFrame = true;
        this._showImagesButton.onClickSignal.add(() => this.onShowImagesClicked());
        this._contentBox.addChild(this._showImagesButton);
    }

    protected updatePositionProgress() {
        let hProgress = this._horizontalProgress + 1;
        let vProgress = this._verticalProgress;
        let shadowScale = this.view.offsetWidth / SizeAndPosition.MAX_TEXT_WIDTH;
        if (shadowScale > 1) shadowScale = 1;
        TweenMax.set([this._titleField.view, this._subTitleField.view], {
            textShadow: !AppConfig.SHOW_TEXT_SHADOWS || SizeAndPosition.IS_SMALL_SCREEN ? "none" : (0) + "px " + (-SizeAndPosition.STAGE_HEIGHT * 0.3 * shadowScale * vProgress + SizeAndPosition.STAGE_HEIGHT * 0.04) + "px " + (60 * shadowScale) + "px rgba(0, 0, 0, " + (0.45 * (1 - Math.abs(vProgress))) + ")",
            autoAlpha: 1 - Math.abs(this._horizontalProgress),
            color: Color.calcColorBetween(Color.DARK_GREY, Color.GREY, hProgress)
        });
        TweenMax.set(this._descField.view, {
            textShadow: !AppConfig.SHOW_TEXT_SHADOWS || SizeAndPosition.IS_SMALL_SCREEN ? "none" : (hProgress * shadowScale) + "px " + (7 * shadowScale * hProgress) + "px " + (25 * shadowScale * hProgress) + "px rgba(0, 0, 0, " + (0.45 * hProgress) + ")",
            autoAlpha: 1 - Math.abs(this._horizontalProgress),
            color: Color.calcColorBetween(Color.DARK_GREY, Color.GREY, hProgress)
        });
        TweenMax.set(this._headerTitle.view, {
            autoAlpha: SizeAndPosition.USE_LANDSCAPE_MODE ? 0 : Math.abs(this._horizontalProgress)
        });
        TweenMax.set(this._showImagesButton.view, {
            autoAlpha: 1 - Math.abs(this._horizontalProgress)
        })
    }

    private scaleToWidth(textField: UIView<HTMLDivElement>) {
        textField.applyStyle({
            scale: 1,
            display: "inline-block",
            fontSize: 100,
            letterSpacing: -6 + "px",
        });
        let textFieldWidth = textField.view.getBoundingClientRect().width;
        let viewWidth = this._contentBox.view.getBoundingClientRect().width;
        let scale = viewWidth / textFieldWidth;
        if (!viewWidth) return;
        textField.applyStyle({
            display: "block",
            x: this.getLineOffsetX(textField.view.innerText),
            fontSize: 100 * scale,
            letterSpacing: -6 * scale + "px",
            lineHeight: 80 * scale + "px"
        });
        this.applyStyle({
            visibility: "visible"
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private getLineOffsetX(innerText: string): string {
        let firstLetter = innerText.substr(0, 1).toLowerCase();
        switch (firstLetter) {
            case "i":
                return "-1.8%";
            default:
                return "-1.5%";
        }
    }

    private onShowImagesClicked() {
        this.onNextSlideSignal.dispatch();
    }
}
