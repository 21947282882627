import {AbstractPageSlideContent} from "./AbstractPageSlideContent";
import {SizeAndPosition} from "../../../../../style/SizeAndPosition";
import {UIView} from "../../../../../../@jashson/core-ui/UIView";
import {ArrowNavigation} from "../../arrownavigation/ArrowNavigation";

/******************************************************************
 * AbstractPageSlideScrollableContent
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AbstractPageSlideScrollableContent extends AbstractPageSlideContent{

    /******************************************************************
     * Properties
     *****************************************************************/

    protected _contentBox: UIView<HTMLDivElement>;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(type: string, isFirstSlide: boolean = false, contentDeeplinks: string[] = null) {
        super(type, isFirstSlide, contentDeeplinks);
        this.initContentBox();
        this.initScrollListener();
        this.onHorizontalCenterLeaveSignal.add(() => {
            ArrowNavigation.PREVENT_NAVIGATION = false;
        })
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            position: "relative",
            width: "100%",
            height: "100%",
            userSelect: "none",
            visibility: this.view.parentElement.offsetWidth ? "visible" : "hidden"
        });
        this._contentBox.applyStyle({
            maxHeight: "unset",
            overflow: "visible",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "70%",
            padding: "0 20%",
            maxWidth: SizeAndPosition.MAX_TEXT_WIDTH + "px",
            x: "-50%",
            y: "-50%"
        });
        let availableContentHeight = this.view.offsetHeight - (SizeAndPosition.USE_LANDSCAPE_MODE ? 40 : 160);
        let useScrolling = this._contentBox.view.offsetHeight - availableContentHeight > 0;
        this._contentBox.interactive = useScrolling;
        if (useScrolling) {
            this._contentBox.applyStyle({
                maxHeight: availableContentHeight + "px",
                overflow: "scroll",
                webkitOverflowScrolling: "touch"
            })
        }
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initContentBox() {
        this._contentBox = new UIView("content-box");
        this.addChild(this._contentBox);
    }

    private initScrollListener() {
        this._contentBox.view.addEventListener("scroll", () => {
            ArrowNavigation.PREVENT_NAVIGATION = false;
            let scrollDelta = this._contentBox.view.scrollHeight - this._contentBox.view.offsetHeight;
            if (this._contentBox.view.scrollTop > 0 && this._contentBox.view.scrollTop < scrollDelta) {
                ArrowNavigation.PREVENT_NAVIGATION = true;
            }
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

}
