import {AbstractPageSlideContent} from "../AbstractPageSlideContent";

/******************************************************************
 * ShowMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ShowMenu extends AbstractPageSlideContent {

    /******************************************************************
     * Properties
     *****************************************************************/

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("show-menu-content");
        this.initListeners();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    /******************************************************************
     * Events
     *****************************************************************/

    private initListeners() {
        this.onHorizontalCenterLeaveSignal.add(() => {
            this.onHideMenuSignal.dispatch();
        });
        this.onHorizontalCenterReachSignal.add(() => {
            this.onShowMenuSignal.dispatch();
        });
    }
}
