/******************************************************************
 * ElementStyle
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/
import {Color} from "./Color";

export class ElementStyle {

    static applyContentCopyAnchorStyle(elements: HTMLElement[]) {
        elements.forEach((element: HTMLElement) => {
            element.style.pointerEvents = "all";
            element.style.color = Color.GREY;
            element.style.textDecoration = "none";
            element.addEventListener("mouseover", () => {
                element.style.color = Color.WHITE;
            });
            element.addEventListener("mouseout", () => {
                element.style.color = Color.GREY;
            });
        });
    }

    static applyMetaCopyAnchorStyle(elements: HTMLElement[]) {
        elements.forEach((element: HTMLElement) => {
            element.style.pointerEvents = "all";
            element.style.color = Color.GREY;
            element.addEventListener("mouseover", () => {
                element.style.color = Color.WHITE;
            });
            element.addEventListener("mouseout", () => {
                element.style.color = Color.GREY;
            });
        });
    }
}
