/******************************************************************
 * PageSlideModel
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PageSlideModel {

    /******************************************************************
     * Properties
     *****************************************************************/

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _type: string, private _data: any, private _deeplink: string, private _contentDeeplinks: string[] = null) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get type(): string {
        return this._type;
    }

    get data(): any {
        return this._data;
    }

    get deeplink(): string {
        return this._deeplink;
    }

    get contentDeeplinks(): string[] {
        return this._contentDeeplinks;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/


    /******************************************************************
     * Events
     *****************************************************************/

}
