import {UIView} from "../../../../../@jashson/core-ui/UIView";
import {SVGView} from "../../../../../@jashson/core-svg/SVGView";
import {TweenMax, Linear, Sine} from "gsap";
import {SizeAndPosition} from "../../../../style/SizeAndPosition";
import {AppConfig} from "../../../../config/AppConfig";
import {DeviceUtils} from "../../../../../@jashson/core-utils/DeviceUtils";

/******************************************************************
 * AppBackgroundCircles
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AppBackgroundCircles extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _circlesSVG: SVGView;
    private _circleElements: SVGElement[];
    private _circleBox: SVGElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("app-background-circles");
        this.initCircles();
        this.initElements();
        this.playIntro();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            width: SizeAndPosition.STAGE_WIDTH,
            height: SizeAndPosition.STAGE_HEIGHT,
            overflow: "hidden"
        });
        let circleWidth = SizeAndPosition.STAGE_WIDTH * 2;
        let circleHeight = SizeAndPosition.STAGE_HEIGHT * 2;
        this._circlesSVG.applyStyle({
            position: "absolute",
            width: circleWidth,
            height: circleHeight,
            top: 0,
            left: 0
        });
        this._circlesSVG.width = circleWidth;
        this._circlesSVG.height = circleHeight;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initCircles() {
        this._circlesSVG = new SVGView(require("ASSETS/images/circles-logo.svg"));
        this._circlesSVG.clearSizeDefinition();
        if (!DeviceUtils.IS_IE_OR_EDGE) {
            this.addChild(this._circlesSVG);
        }
    }

    private initElements() {
        this._circleBox = this._circlesSVG.getElementByID("circles-logo");
        this._circleElements = Array.from(this._circlesSVG.getElementByID("circles").childNodes) as SVGElement[];
        TweenMax.set(this._circlesSVG.getElementByID("viewbox"), {
            autoAlpha: 0
        });
        TweenMax.set(this._circleElements, {
            stroke: "rgba(255, 255, 255, 0.15)",
            strokeWidth: 0.5,
            fill: "rgba(255, 255, 255, 0)"
        });
        if (!DeviceUtils.IS_IE_OR_EDGE) {
            TweenMax.set(this._circleElements, {
                vectorEffect: "non-scaling-stroke"
            });
        }
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private playIntro() {
        TweenMax.set(this.view, {
            alpha: 0
        });
        TweenMax.to(this.view, 2, {
            delay: AppConfig.SHOW_UI_DELAY,
            alpha: 1
        });
        this.moveAround(true);
        if (!AppConfig.ANIMATE_BACKGROUND_CIRCLES) return;
        TweenMax.to(this._circleBox, 240, {
            transformOrigin: "50% 50%",
            rotation: 360,
            repeat: -1,
            ease: Linear.easeNone
        });
    }

    private moveAround(instantly: boolean = false) {
        TweenMax.to(this._circleBox, instantly ? 0.1 : 120, {
            x: -(Math.random() * 15 + 50) + "%",
            y: "-25%",
            ease: Sine.easeInOut,
            onComplete: () => {
                if (!AppConfig.ANIMATE_BACKGROUND_CIRCLES) return;
                this.moveAround();
            }
        })
    }
}
