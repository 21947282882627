/******************************************************************
 * MenuItemModel
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class MenuItemModel {

    /******************************************************************
     * Properties
     *****************************************************************/


    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _itemVO: any) {

    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get name(): string {
        return this._itemVO.title;
    }

    get deeplink(): string {
        return this._itemVO.deeplink;
    }

    get isPersonal(): boolean {
        return this._itemVO.isPersonal;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/


    /******************************************************************
     * Events
     *****************************************************************/

}
