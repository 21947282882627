import {UIView} from "../../../../../@jashson/core-ui/UIView";
import {PageModel} from "./PageModel";
import {PageSlideModel} from "./PageSlideModel";
import {PageSlide} from "./PageSlide";
import {SizeAndPosition} from "../../../../style/SizeAndPosition";
import {Power4, TweenMax} from "gsap";
import {GSAPUtils} from "../../../../../@jashson/core-utils/GSAPUtils";
import {PageSlideType} from "./PageSlideType";
import * as signals from "signals";

/******************************************************************
 * Page
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Page extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _currentSlideID: number = -1;
    private _fixedSlides: UIView<HTMLDivElement>;
    private _movingSlides: UIView<HTMLDivElement>;
    private _verticalProgress: number = 0; // -1 0 1 / top center bottom
    private _movingSlidesDict: PageSlide[] = [];

    public onNextSlideSignal = new signals.Signal();
    public onPrevSlideSignal = new signals.Signal();
    public onContentChangeSignal = new signals.Signal();
    public onShowMenuSignal = new signals.Signal();
    public onHideMenuSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _model: PageModel) {
        super("page_" + _model.title.toLowerCase());
        this.initContainers();
        this.initSlides();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get hasNextSlide(): boolean {
        return this._currentSlideID < this._movingSlidesDict.length;
    }

    get hasPrevSlide(): boolean {
        return this._currentSlideID > 0;
    }

    get currentSlide(): PageSlide {
        if (this._currentSlideID == 0 && this._fixedSlides) {
            return this._fixedSlides.children[0] as PageSlide;
        }
        return this._movingSlidesDict[this._currentSlideID - 1] as PageSlide;
    }

    get nextSlide(): PageSlide {
        if (!this.hasNextSlide) return;
        return this._movingSlidesDict[this._currentSlideID] as PageSlide;
    }

    get prevSlide(): PageSlide {
        if (!this.hasPrevSlide) return;
        if (this._currentSlideID == 1 && this._fixedSlides) {
            return this._fixedSlides.children[0] as PageSlide;
        }
        return this._movingSlidesDict[this._currentSlideID - 2] as PageSlide;
    }

    get verticalProgress(): number {
        return this._verticalProgress;
    }

    set verticalProgress(value: number) {
        this._verticalProgress = value;
        this._movingSlides.children.forEach((pageSlide: PageSlide) => {
            pageSlide.verticalProgress = value;
        });
        this._fixedSlides.children.forEach((pageSlide: PageSlide) => {
            pageSlide.verticalProgress = value;
        });
    }

    public getSlideID(deeplink: string): number {
        let slideID = 0;
        this._movingSlidesDict.forEach((slide: PageSlide, i: number) => {
            if (slide.contentDeeplinks) {
                slide.contentDeeplinks.forEach((contentDeeplink: string, j: number) => {
                    if (deeplink == contentDeeplink) {
                        slideID = i + 1;
                    }
                });
            } else {
                if (slide.deeplink == deeplink) {
                    slideID = i + 1;
                }
            }
        });
        return slideID;
    }

    public getSlideContentID(deeplink: string): number {
        let slideContentID = undefined;
        this._movingSlidesDict.forEach((slide: PageSlide) => {
            if (slide.contentDeeplinks) {
                slide.contentDeeplinks.forEach((contentDeeplink: string, i: number) => {
                    if (deeplink == contentDeeplink) {
                        slideContentID = i;
                    }
                });
            }
        });
        return slideContentID;
    }

    public gotoSlide(slideID: number, instantly: boolean = false) {
        let prepareNextSlide = slideID != this._currentSlideID;
        if (slideID < 0) slideID = 0;
        if (slideID > this._model.slideModels.length - 1) slideID = this._model.slideModels.length - 1;
        this._currentSlideID = slideID;
        let isRunning = TweenMax.isTweening(this._movingSlides.view);
        if (prepareNextSlide) {
            if (this.hasNextSlide) {
                if (!this.nextSlide.isStaged) {
                    this._movingSlides.addChild(this.nextSlide);
                }
                this.nextSlide.prepareSlide();
            }
            if (this.hasPrevSlide) {
                if (!this.prevSlide.isStaged) {
                    this._movingSlides.addChild(this.prevSlide);
                }
                this.prevSlide.prepareSlide();
            }
        }
        if (!this.currentSlide.isStaged) {
            this._movingSlides.addChild(this.currentSlide);
        }
        TweenMax.killTweensOf(this._movingSlides.view);
        TweenMax.to(this._movingSlides.view, instantly ? 0 : 0.9, {
            x: -slideID * SizeAndPosition.STAGE_WIDTH,
            ease: isRunning ? Power4.easeOut : Power4.easeInOut,
            onUpdate: () => {
                this.updateForegroundProgresses();
            }
        });
    }

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            top: 0,
            left: 0
        });
        this._movingSlides.applyStyle({
            position: "absolute"
        });
        this._fixedSlides.applyStyle({
            position: "absolute"
        });
        this._movingSlidesDict.forEach((pageSlide: PageSlide) => {
            pageSlide.applyStyle({
                x: pageSlide.intendedSlideID * SizeAndPosition.STAGE_WIDTH
            })
        });
        this.gotoSlide(this._currentSlideID, true);
    }

    public updateSliderStyles() {
        this._movingSlides.children.forEach((pageSlide: PageSlide) => {
            pageSlide.updateStyles();
        });
        this._fixedSlides.children.forEach((pageSlide: PageSlide) => {
            pageSlide.updateStyles();
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initSlides() {
        if (!this._model.slideModels) return;
        let isFirstMovingSlide = true;
        let intendedSlideID = 0;
        this._model.slideModels.forEach((model: PageSlideModel) => {
            if (this.isFixedSlide(model.type)) {
                let pageSlide = new PageSlide(model, false, intendedSlideID++);
                this.initListeners(pageSlide);
                this._fixedSlides.addChild(pageSlide);
            } else {
                let pageSlide = new PageSlide(model, isFirstMovingSlide, intendedSlideID++);
                this.initListeners(pageSlide);
                this._movingSlidesDict.push(pageSlide);
                isFirstMovingSlide = false;
            }
        });
    }

    private initListeners(pageSlide: PageSlide) {
        pageSlide.onNextSlideSignal.add(() => this.onNextSlideRequested());
        pageSlide.onPrevSlideSignal.add(() => this.onPrevSlideRequested());
        pageSlide.onContentChangeSignal.add((deeplink: string) => this.onContentChangeSignal.dispatch(deeplink));
        pageSlide.onShowMenuSignal.add(() => this.onShowMenuSignal.dispatch());
        pageSlide.onHideMenuSignal.add(() => this.onHideMenuSignal.dispatch());
    }

    private initContainers() {
        this._fixedSlides = new UIView("fixed");
        this.addChild(this._fixedSlides);
        this._movingSlides = new UIView("moving");
        this.addChild(this._movingSlides);
    }

    private updateForegroundProgresses() {
        this._movingSlides.children.forEach((slide: PageSlide, i: number) => {
            slide.horizontalProgress = this.calcForegroundProgress(slide.intendedSlideID);
        });
        this._fixedSlides.children.forEach((slide: PageSlide, i: number) => {
            slide.horizontalProgress = this.calcForegroundProgress(i);
        })
    }

    private calcForegroundProgress(i) {
        let relPosX = i * SizeAndPosition.STAGE_WIDTH + GSAPUtils.getTransformX(this._movingSlides.view);
        let foregroundProgress = relPosX / SizeAndPosition.STAGE_WIDTH;
        if (foregroundProgress > 1) foregroundProgress = 1;
        if (foregroundProgress < -1) foregroundProgress = -1;
        return foregroundProgress;
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onNextSlideRequested() {
        this.onNextSlideSignal.dispatch();
    }

    private onPrevSlideRequested() {
        this.onPrevSlideSignal.dispatch();
    }

    private isFixedSlide(type: string) {
        switch (type) {
            case PageSlideType.CATEGORY_INFO:
            case PageSlideType.ABOUT_ME:
            case PageSlideType.STUDIO:
            case PageSlideType.CONTACT:
                return true;
        }
        return false;
    }
}
