/******************************************************************
 * RequestUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class RequestUtils {

    static getURL(url: string, resultListener: (e: any) => void, requestHeaders: {key: string, value: string}[] = null, usePost: boolean = false, sendData: any = null) {
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
                resultListener(xmlHttp.responseText);
        };
        xmlHttp.open(usePost ? "POST" : "GET", url, true);
		if (requestHeaders) {
			requestHeaders.forEach((header) => {
				xmlHttp.setRequestHeader(header.key, header.value);
			});
		}
        xmlHttp.send(sendData);
    }

}
