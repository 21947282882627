import {PageSlideType} from "../../PageSlideType";
import {HeaderTitle} from "../../../../basic/HeaderTitle";
import {TweenMax} from "gsap";
import {UIView} from "../../../../../../../@jashson/core-ui/UIView";
import {BackgroundImage} from "../../../../basic/BackgroundImage";
import {Color} from "../../../../../../style/Color";
import {FontStyle} from "../../../../../../style/FontStyle";
import {ShadowStyle} from "../../../../../../style/ShadowStyle";
import {SizeAndPosition} from "../../../../../../style/SizeAndPosition";
import {AppConfig} from "../../../../../../config/AppConfig";
import {AbstractPageSlideScrollableContent} from "../AbstractPageSlideScrollableContent";
import {LabelButton} from "../../../../basic/LabelButton";

/******************************************************************
 * About
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class About extends AbstractPageSlideScrollableContent {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _headerTitle: HeaderTitle;
    private _image: BackgroundImage;
    private _descField: UIView<HTMLDivElement>;
    private _showImagesButton: LabelButton;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _title: string, private _desc: string, private _portraitURL: string, private _buttonLabel: string) {
        super(PageSlideType.ABOUT_ME);
        this.initPortraitImage();
        this.initDescField();
        this.initShowImagesButton();
        this.initHeaderTitle();
        this.applyStyle({
            visibility: "hidden"
        });
        this.onHorizontalCenterLeaveSignal.add(() => {
            this._showImagesButton.interactive = false;
        });
        this.onHorizontalCenterReachSignal.add(() => {
            this._showImagesButton.interactive = true;
        });
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this._contentBox.applyStyle({
            textAlign: "center"
        });
        this._descField.applyStyle({
            marginTop: FontStyle.FONT_SIZE_CONTENT_COPY * (SizeAndPosition.IS_SMALL_SCREEN ? 1 : 2),
            color: Color.GREY,
            textAlign: "left",
            fontSize: FontStyle.FONT_SIZE_CONTENT_COPY,
            lineHeight: FontStyle.FONT_SIZE_CONTENT_COPY * 1.4 + "px"
        });
        this._image.applyStyle({
            margin: "auto",
            width: this.calcImageSize(),
            height: this.calcImageSize(),
            overflow: "hidden",
            borderRadius: this.calcImageSize() * 0.5,
            boxShadow: AppConfig.SHOW_TEXT_SHADOWS ? ShadowStyle.STANDARD_SHADOW : "none"
        });
        this._showImagesButton.applyStyle({
            marginTop: FontStyle.FONT_SIZE_CONTENT_COPY
        });
        this._headerTitle.applyStyle({
            display: SizeAndPosition.USE_LANDSCAPE_MODE ? "none" : "block"
        });
        super.updateStyles();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initPortraitImage() {
        this._image = new BackgroundImage();
        this._image.imageURL = this._portraitURL;
        this._contentBox.addChild(this._image);
    }

    private initDescField() {
        this._descField = new UIView("desc-field");
        this._descField.view.innerHTML = this._desc;
        this._contentBox.addChild(this._descField);
    }

    private initShowImagesButton() {
        this._showImagesButton = new LabelButton(this._buttonLabel);
        this._showImagesButton.showFrame = true;
        this._showImagesButton.onClickSignal.add(() => this.onShowImagesClicked());
        this._contentBox.addChild(this._showImagesButton);
    }

    private initHeaderTitle() {
        this._headerTitle = new HeaderTitle(this._title);
        this.addChild(this._headerTitle);
    }

    protected updatePositionProgress() {
        TweenMax.set(this._contentBox.view, {
            autoAlpha: 1 - Math.abs(this._horizontalProgress)
        });
        TweenMax.set(this._headerTitle.view, {
            autoAlpha: 1 - Math.abs(this._verticalProgress)
        })
    }

    private calcImageSize(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 100 : 250;
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onShowImagesClicked() {
        this.onNextSlideSignal.dispatch();
    }

}
