/******************************************************************
 * SizeAndPosition
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SizeAndPosition {

    /* ----------------------------------------------------------------
 	 * STATE
 	 * --------------------------------------------------------------*/

    static get IS_SMALL_SCREEN(): boolean {
        return SizeAndPosition.STAGE_WIDTH < 768 || SizeAndPosition.STAGE_HEIGHT < 600;
    }

    /* ----------------------------------------------------------------
 	 * STAGE
 	 * --------------------------------------------------------------*/

    static get STAGE_WIDTH(): number {
        return window.innerWidth;
    }

    static get STAGE_HEIGHT(): number {
        return window.innerHeight;
    }

    static get USE_LANDSCAPE_MODE(): boolean {
        if (SizeAndPosition.STAGE_WIDTH > SizeAndPosition.STAGE_HEIGHT) {
            if (SizeAndPosition.STAGE_HEIGHT < 550) return true;
        }
        return false;
    }

    /* ----------------------------------------------------------------
 	 * MARGINS
 	 * --------------------------------------------------------------*/

    static get APP_UI_MARGIN(): number {
        let margin = Math.round(SizeAndPosition.STAGE_WIDTH * 0.02);
        if (margin < 15) margin = 15;
        if (margin > 20) margin = 20;
        if (SizeAndPosition.USE_LANDSCAPE_MODE) {
            margin = 5;
        }
        return margin;
    }

    static get LANDSCAPE_MODE_APP_MARGIN(): number {
        return 20;
    }

    static get HEADER_HEIGHT(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 75 : 120
    }

    static get FOOTER_HEIGHT(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 55 : 100
    }

    static get PREVIEW_MARGIN(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 20 : 100
    }

    /* ----------------------------------------------------------------
 	 * GENERAL
 	 * --------------------------------------------------------------*/

    static get MAX_TEXT_WIDTH(): number {
        return 900;
    }

    static get ICON_BUTTON_SIZE(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 30 : 55;
    }

    /* ----------------------------------------------------------------
 	 * THUMBNAIL
 	 * --------------------------------------------------------------*/

    static get THUMBNAIL_HEIGHT(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 30 : 50;
    }

    static get THUMBNAIL_OFFSET_Y(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 10 : 10
    };

    static get THUMBNAIL_MARGIN(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 10 : 20
    };

    static get THUMBNAIL_BORDER_RADIUS(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 5 : 8
    };

}
