import {UIView} from "../../../../@jashson/core-ui/UIView";
import {ILinkVO} from "../../../vo/ILinkVO";
import {SVGView} from "../../../../@jashson/core-svg/SVGView";
import {URLUtils} from "../../../../@jashson/core-utils/URLUtils";
import {TweenMax, Power4} from "gsap";
import {Color} from "../../../style/Color";
import {ShadowStyle} from "../../../style/ShadowStyle";
import * as signals from "signals";
import {SizeAndPosition} from "../../../style/SizeAndPosition";

/******************************************************************
 * IconButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class IconButton extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _iconSVG: SVGView;

    public onClickSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _iconURL: string) {
        super("icon-button");
        this.initButtonIcon();
        this.initListener();
        this.interactive = true;
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            cursor: "pointer"
        });
        if (!this._iconSVG) return;
        this._iconSVG.applyStyle({
            fontSize: 0,
            overflow: "hidden",
            display: "inline-block",
            borderRadius: 200,
            boxShadow: ShadowStyle.SMALL_SHADOW,
            scale: 1
        });
        this._iconSVG.width = SizeAndPosition.ICON_BUTTON_SIZE;

    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initButtonIcon() {
        this._iconSVG = new SVGView(this._iconURL);
        this._iconSVG.clearSizeDefinition();
        this.addChild(this._iconSVG);
    }

    private initListener() {
        this._iconSVG.view.addEventListener("click", () => this.onClicked());
        this._iconSVG.view.addEventListener("mouseover", () => this.onMouseOver());
        this._iconSVG.view.addEventListener("mouseout", () => this.onMouseOut());
    }

    private highlight(value: boolean) {
        TweenMax.to(this._iconSVG.view, 0.5, {
            boxShadow: value ? ShadowStyle.STANDARD_SHADOW : ShadowStyle.SMALL_SHADOW,
            ease: Power4.easeOut,
            scale: value ? 1.1 : 1
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onClicked() {
        this.highlight(false);
        this.onClickSignal.dispatch();
    }

    private onMouseOver() {
        this.highlight(true);
    }

    private onMouseOut() {
        this.highlight(false);
    }
}
