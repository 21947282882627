/******************************************************************
 * SVGUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SVGUtils {

    static getElementByID(svgElement: SVGElement, idPrefix: string, id: string): SVGElement {
        return svgElement.querySelectorAll("#" + idPrefix + id)[0] as SVGElement;
    }

    static getElementsByPartOfID(svgElement: SVGElement, idPrefix: string, partofID: string): SVGElement[] {
        return Array.from(svgElement.querySelectorAll("[id^=" + idPrefix + partofID + "]")) as SVGElement[];
    }

    static getLocalTransform(svgElement: SVGElement): { x: number, y: number } {
        let transform = svgElement.getAttribute("transform");
        if (transform) {
            let translate = transform.replace("translate(", "").replace(")", "").replace(" ", "");
            let x = parseFloat(translate.split(",")[0]);
            let y = parseFloat(translate.split(",")[1]);
            if (translate.indexOf("matrix") != -1) {
                translate = translate.replace("matrix(", "");
                x = parseFloat(translate.split(",")[4]);
                y = parseFloat(translate.split(",")[5]);
            }
            // FIX FOR IE11
            if (translate.indexOf(",") == -1) {
                translate = transform.replace("translate(", "").replace(")", "").replace(", ", "");
                x = parseFloat(translate.split(" ")[0]);
                y = parseFloat(translate.split(" ")[1]);
            }
            return {x: x, y: y};
        }
        return null;
    }
}
