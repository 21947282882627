import {UIView} from "../../../../../../../../@jashson/core-ui/UIView";
import {ILinkVO} from "../../../../../../../vo/ILinkVO";
import {LinkButton} from "../../../../../basic/LinkButton";
import {SizeAndPosition} from "../../../../../../../style/SizeAndPosition";

/******************************************************************
 * LinkButtons
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class LinkButtons extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/


    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _linkVOs: ILinkVO[]) {
        super("link-buttons");
        this.initLinkButtons();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            whiteSpace: "nowrap"
        });
        this.children.forEach((linkButton: LinkButton, i: number) => {
            linkButton.applyStyle({
                display: "inline-block",
                marginLeft: i > 0 ? SizeAndPosition.THUMBNAIL_MARGIN : 0
            });
        });
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initLinkButtons() {
        this._linkVOs.forEach((linkVO: ILinkVO) => {
            this.addChild(new LinkButton(linkVO));
        });
    }

    /******************************************************************
     * Events
     *****************************************************************/


}
