import {UIView} from "../../@jashson/core-ui/UIView";
import {Menu} from "../ui/components/complex/menu/Menu";
import {MenuModel} from "../ui/components/complex/menu/MenuModel";
import {AppBackground} from "../ui/components/complex/appbackground/AppBackground";
import {ArrowNavigation} from "../ui/components/complex/arrownavigation/ArrowNavigation";
import {Pages} from "../ui/components/complex/pages/Pages";
import {FocusMap} from "../ui/components/complex/focusmap/FocusMap";
import {SitemapModel} from "../model/SitemapModel";
import {MetaNavigation} from "../ui/components/complex/metanavigation/MetaNavigation";
import {MetaLayer} from "../ui/components/complex/metalayer/MetaLayer";
import {ProgressCircles} from "../ui/components/basic/ProgressCircles";

/******************************************************************
 * CoreView
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CoreView extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _menu: Menu;
    private _appBackground: AppBackground;
    private _arrowNavigation: ArrowNavigation;
    private _pages: Pages;
    private _focusMap: FocusMap;
    private _metaNavigation: MetaNavigation;
    private _metaLayer: MetaLayer;
    private _progressCircles: ProgressCircles;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("coreview");
        this.initBackground();
        this.initPages();
        this.initMetaLayer();
        this.initProgressCircles();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get metaNavigation(): MetaNavigation {
        return this._metaNavigation;
    }

    get focusMap(): FocusMap {
        return this._focusMap;
    }

    get arrowNavigation(): ArrowNavigation {
        return this._arrowNavigation;
    }

    get menu(): Menu {
        return this._menu;
    }

    get pages(): Pages {
        return this._pages;
    }

    get metaLayer(): MetaLayer {
        return this._metaLayer;
    }

    public initNavigation(menuModel: MenuModel, sitemapModel: SitemapModel) {
        this._progressCircles.hide();
        this._menu = new Menu(menuModel);
        this._arrowNavigation = new ArrowNavigation(this);
        this._focusMap = new FocusMap(sitemapModel);
        this._metaNavigation = new MetaNavigation();
        this._appBackground.showCircles();
        this.addChild(this._menu);
        this.addChild(this._arrowNavigation);
        this.addChild(this._focusMap);
        this.addChild(this._metaNavigation);
    }

    public updateStyles() {
        this._progressCircles.applyStyle({
            position: "fixed",
            top: "50vh",
            left: "50vw",
            x: "-50%",
            y: "-50%"
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initBackground() {
        this._appBackground = new AppBackground();
        this.addChild(this._appBackground);
    }

    private initPages() {
        this._pages = new Pages();
        this.addChild(this._pages);
    }

    private initMetaLayer() {
        this._metaLayer = new MetaLayer();
        this.addChild(this._metaLayer);
    }

    private initProgressCircles() {
        this._progressCircles = new ProgressCircles();
        this._progressCircles.hide(true);
        this._progressCircles.show();
        this.addChild(this._progressCircles);
    }

    /******************************************************************
     * Events
     *****************************************************************/


}
