import * as signals from "signals";

/******************************************************************
 * GenericTextModel
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class GenericTextModel {

	/******************************************************************
	 * Properties
	 *****************************************************************/

	private static _GENERIC_TEXT_JSON: any;
	private static _IS_AVAILABLE: boolean;

	public static onAvailableSignal = new signals.Signal();

	/******************************************************************
	 * Init
	 *****************************************************************/

	static init(genericTextJSON: any) {
		this._GENERIC_TEXT_JSON = genericTextJSON;
		this.onAvailableSignal.dispatch();
		this._IS_AVAILABLE = true;
	}

	/******************************************************************
	 * Public Methodes
	 *****************************************************************/

    static get APP_TITLE(): string {
        return this._GENERIC_TEXT_JSON.appTitle;
    };

    static get IMPRINT_PRIVACY_TITLE(): string {
        return this._GENERIC_TEXT_JSON.imprintPrivacyTitle;
    }

    static get SITE_DESC(): string {
        return this._GENERIC_TEXT_JSON.siteDesc;
    }
}
