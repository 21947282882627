import {IRouteVO} from "../vo/IRouteVO";
import {CoreRouter} from "../core/CoreRouter";
import {PageModel} from "../ui/components/complex/pages/PageModel";
import {PageSlideModel} from "../ui/components/complex/pages/PageSlideModel";

/******************************************************************
 * SitemapModel
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SitemapModel {

    /******************************************************************
     * Properties
     *****************************************************************/


    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _pageModels: PageModel[], private _imprintPrivacyDeeplink: string) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get isCurrentDeeplinkHomePage(): boolean {
        return this.getRoute(CoreRouter.CURRENT_DEEPLINK).deeplink == "";
    }

    get pageModels(): PageModel[] {
        return this._pageModels;
    }

    public getRoute(deeplink: string): IRouteVO {
        let routeVO: IRouteVO = {deeplink: ""};
        if (deeplink == this._imprintPrivacyDeeplink) {
            return {
                deeplink: this._imprintPrivacyDeeplink
            }
        }
        this._pageModels.forEach((pageModel: PageModel) => {
            if (deeplink.indexOf(pageModel.deeplink) != -1) {
                routeVO.deeplink = deeplink;
            }
            if (pageModel.slideModels) {
                pageModel.slideModels.forEach((slideModel: PageSlideModel) => {
                    if (slideModel.deeplink == deeplink) {
                        routeVO.deeplink = deeplink;
                    }
                })
            }
        });
        return routeVO;
    }

    public getNextPageRoute(): IRouteVO {
        if (this.isCurrentDeeplinkHomePage && this._pageModels.length > 0) {
            return {
                deeplink: this._pageModels[0].deeplink
            }
        }
        let currentPageID = this.getPageID(CoreRouter.CURRENT_DEEPLINK);
        if (currentPageID == undefined || !this._pageModels[currentPageID + 1]) {
            return this.createHomepageRoute();
        }
        return {
            deeplink: this._pageModels[currentPageID + 1].deeplink
        };
    }

    public getPrevPageRoute(): IRouteVO {
        let currentPageID = this.getPageID(CoreRouter.CURRENT_DEEPLINK);
        if (currentPageID == undefined || !this._pageModels[currentPageID - 1]) {
            return this.createHomepageRoute();
        }
        return {
            deeplink: this._pageModels[currentPageID - 1].deeplink
        };
    }

    public getPrevPageSlideRoute() {
        let currentDeeplink = CoreRouter.CURRENT_DEEPLINK;
        let currentPageID = this.getPageID(currentDeeplink);
        if (currentPageID == undefined) {
            return this.createHomepageRoute();
        }
        let currentPageModel = this.getPageModel(currentDeeplink);
        let routeVO: IRouteVO = {
            deeplink: currentPageModel.deeplink
        };
        if (currentDeeplink == currentPageModel.deeplink && currentPageModel.slideModels.length > 1) {
            routeVO.deeplink = currentPageModel.slideModels[1].deeplink;
        } else {
            let currentPageSlideID = this.getPageSlideID(currentDeeplink);
            if (currentPageSlideID && currentPageModel.slideModels[currentPageSlideID - 1]) {
                routeVO.deeplink = currentPageModel.slideModels[currentPageSlideID - 1].deeplink
            }
        }
        return routeVO;
    }

    public getNextPageSlideRoute() {
        let currentDeeplink = CoreRouter.CURRENT_DEEPLINK;
        let currentPageID = this.getPageID(currentDeeplink);
        if (currentPageID == undefined) {
            return this.createHomepageRoute();
        }
        let currentPageModel = this.getPageModel(currentDeeplink);
        let routeVO: IRouteVO = {
            deeplink: currentPageModel.deeplink
        };
        if (currentDeeplink == currentPageModel.deeplink && currentPageModel.slideModels.length > 1) {
            routeVO.deeplink = currentPageModel.slideModels[1].deeplink;
        } else {
            let currentPageSlideID = this.getPageSlideID(currentDeeplink);
            if (currentPageSlideID && currentPageModel.slideModels[currentPageSlideID + 1]) {
                routeVO.deeplink = currentPageModel.slideModels[currentPageSlideID + 1].deeplink
            }
        }
        return routeVO;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private getPageID(deeplink: string) {
        let pageID: number = undefined;
        this._pageModels.forEach((pageModel: PageModel, i: number) => {
            if (deeplink.indexOf(pageModel.deeplink) != -1) {
                pageID = i;
            }
        });
        return pageID;
    }

    private getPageModel(deeplink: string) {
        let model: PageModel = undefined;
        this._pageModels.forEach((pageModel: PageModel, i: number) => {
            if (deeplink.indexOf(pageModel.deeplink) != -1) {
                model = pageModel;
            }
        });
        return model;
    }

    private getPageSlideID(deeplink: string): number {
        let pageSlideID: number = undefined;
        this.getPageModel(deeplink).slideModels.forEach((slideModel: PageSlideModel, i: number) => {
            if (slideModel.contentDeeplinks) {
                slideModel.contentDeeplinks.forEach((contentDeeplink: string, j: number) => {
                    if (deeplink == contentDeeplink) {
                        pageSlideID = i;
                    }
                });
            } else {
                if (slideModel.deeplink == deeplink) {
                    pageSlideID = i;
                }
            }
        });
        return pageSlideID;
    }

    private createHomepageRoute(): IRouteVO {
        return {deeplink: ""};
    }

    /******************************************************************
     * Events
     *****************************************************************/


}
