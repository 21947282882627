import {UIView} from "../../../../../../@jashson/core-ui/UIView";
import {MenuItemModel} from "./MenuItemModel";
import {Color} from "../../../../../style/Color";
import {FontStyle} from "../../../../../style/FontStyle";
import {TweenMax, Power4} from "gsap";
import * as signals from "signals";
import {ShadowStyle} from "../../../../../style/ShadowStyle";
import {SizeAndPosition} from "../../../../../style/SizeAndPosition";

/******************************************************************
 * MenuItem
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class MenuItem extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _labelField: UIView<HTMLDivElement>;
    private _isVertical: boolean = false;
    private _dispatchUpdateSignal = true;

    public onUpdateGroupSignal = new signals.Signal();
    public onItemClickSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _model: MenuItemModel) {
        super("menu-item");
        this.createLabelField();
        this.initListeners();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    set isVertical(value: boolean) {
        this._isVertical = value;
        this._dispatchUpdateSignal = false;
        this.updateStyles();
        this._dispatchUpdateSignal = true;
    }

    get itemWidth(): number {
        return this.view.getBoundingClientRect().width;
    }

    public updateStyles() {
        this.applyStyle({
            display: this._isVertical ? "block" : "inline-block"
        });
        this._labelField.applyStyle({
            color: Color.GREY,
            fontWeight: FontStyle.FONT_WEIGHT_MEDIUM,
            fontSize: FontStyle.FONT_SIZE_MENU_ITEM,
            paddingLeft: this._isVertical ? 0 : FontStyle.FONT_SIZE_MENU_ITEM,
            paddingRight: this._isVertical ? 0 : FontStyle.FONT_SIZE_MENU_ITEM,
            paddingTop: this._isVertical ? this.calcVerticalPadding() : 0,
            paddingBottom: this._isVertical ? this.calcVerticalPadding() : 0,
            letterSpacing: FontStyle.FONT_SIZE_MENU_ITEM * 0.05 + "px",
            textTransform: "uppercase",
            cursor: "pointer",
            textAlign: this._isVertical ? "center" : "left"
        });
        if (this._dispatchUpdateSignal) {
            this.onUpdateGroupSignal.dispatch();
        }
    }

    public highlight(value: boolean) {
        let shadowY = FontStyle.FONT_SIZE_MENU_ITEM * 0.2 + "px";
        let shadowBlur = FontStyle.FONT_SIZE_MENU_ITEM * 0.5 + "px";
        if (!value) {
            shadowY = 0 + "px";
            shadowBlur = 0 + "px";
        }
        TweenMax.to(this._labelField.view, value ? 0.1 : 0.3, {
            color: value ? Color.WHITE : Color.GREY,
        });
        TweenMax.to(this._labelField.view, 0.5, {
            textShadow: "0 " + shadowY + " " + shadowBlur + " " + ShadowStyle.BLACK_SHADOW_RGBA,
            ease: Power4.easeOut
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private createLabelField() {
        this._labelField = new UIView("label");
        this._labelField.view.innerText = this._model.name;
        this.addChild(this._labelField);
    }

    private initListeners() {
        this._labelField.view.addEventListener("click", () => this.onClicked());
        this._labelField.view.addEventListener("mouseenter", () => this.onEntered());
        this._labelField.view.addEventListener("mouseleave", () => this.onLeft());
    }

    private calcVerticalPadding(): number {
        return FontStyle.FONT_SIZE_MENU_ITEM * (SizeAndPosition.STAGE_HEIGHT < 500 ? 0.5 : 0.75);
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onClicked() {
        this.onItemClickSignal.dispatch(this._model.deeplink);
    }

    private onEntered() {
        this.highlight(true);
    }

    private onLeft() {
        this.highlight(false);
    }
}
