import {UIView} from "../../../../../../@jashson/core-ui/UIView";
import {MenuItemModel} from "./MenuItemModel";
import {MenuItem} from "./MenuItem";
import * as signals from "signals";

/******************************************************************
 * MenuItemGroup
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class MenuItemGroup extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    public onGroupWidthChangeSignal = new signals.Signal();
    public onItemClickSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _groupItemModels: MenuItemModel[]) {
        super("menu-item-group");
        this.initGroupItems();
        this.interactive = true;
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    set useVerticalMenu(value: boolean) {
        this.children.forEach((item: MenuItem) => {
            item.isVertical = value;
        })
    }

    get groupWidth(): number {
        let width = 0;
        this.children.forEach((item: MenuItem) => {
            width += item.itemWidth;
        });
        return width;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initGroupItems() {
        this._groupItemModels.forEach((model: MenuItemModel) => {
            let item = new MenuItem(model);
            item.onItemClickSignal.add(() => this.onItemClickSignal.dispatch(model.deeplink));
            this.addChild(item);
        });
        let lastItem = this.children[this.children.length - 1] as MenuItem;
        lastItem.onUpdateGroupSignal.add(() => {
            requestAnimationFrame(() => {
                this.onGroupWidthChangeSignal.dispatch()
            })
        });
    }

    /******************************************************************
     * Events
     *****************************************************************/


}
