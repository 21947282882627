import {CoreView} from "./CoreView";
import {CoreData} from "./CoreData";
import {CoreRouter} from "./CoreRouter";
import {IRouteVO} from "../vo/IRouteVO";
import {TweenMax} from "gsap";
import {AppConfig} from "../config/AppConfig";
import {GenericTextModel} from "../model/GenericTextModel";

/******************************************************************
 * CoreController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CoreController {

    /******************************************************************
     * Properties
     *****************************************************************/


    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _coreView: CoreView, private _coreData: CoreData, private _coreRouter: CoreRouter) {
        this._coreData.onSiteDataLoadSignal.addOnce(() => this.onSiteDataLoaded());
        this.start();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/


    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initListener() {
        this._coreRouter.onRouteSignal.add((route: IRouteVO) => this.onRouteSignal(route));
        this._coreView.menu.onItemClickSignal.add((deeplink: string) => this.onDeeplinkClicked(deeplink));
        this._coreView.menu.onShowMenuSignal.add(() => this.onShowMenu());
        this._coreView.menu.onHideMenuSignal.add(() => this.onHideMenu());
        this._coreView.metaNavigation.onClickSignal.add(() => this._coreView.metaLayer.show());
        this._coreView.arrowNavigation.onDownSignal.add(() => this.onDown());
        this._coreView.arrowNavigation.onUpSignal.add(() => this.onUp());
        this._coreView.arrowNavigation.onLeftSignal.add(() => this.onLeft());
        this._coreView.arrowNavigation.onRightSignal.add(() => this.onRight());
        this._coreView.pages.onNextSlideSignal.add(() => this.onRight());
        this._coreView.pages.onPrevSlideSignal.add(() => this.onLeft());
        this._coreView.pages.onContentChangeSignal.add((deeplink: string) => this.onDeeplinkClicked(deeplink));
        this._coreView.pages.onShowMenuSignal.add(() => this.onShowMenuSlideReached());
        this._coreView.pages.onHideMenuSignal.add(() => this.onShowMenuSlideLeft());
        this._coreView.metaLayer.onShowSignal.add(() => this.onShowMetaLayer());
        this._coreView.metaLayer.onHideSignal.add(() => this.onHideMetaLayer());
    }

    private start() {
        this._coreData.loadSiteData();
    }

    private updateMenu() {
        if (this._coreRouter.isHomepage) {
            this._coreView.menu.moveToCenter();
        } else {
            this._coreView.menu.moveToTop();
            this._coreView.pages.show();
        }
    }

    private updatePage(route: IRouteVO, isInital: boolean = false, onPageUpdated: () => any) {
        this._coreView.pages.showPage(this._coreView.pages.getPageID(route.deeplink), isInital);
        if (this._coreView.pages.currentPage) {
            TweenMax.delayedCall(isInital ? 0.1 : 0, () => {
                this._coreView.pages.currentPage.gotoSlide(this._coreView.pages.currentPage.getSlideID(route.deeplink));
                this._coreView.pages.currentPage.currentSlide.showContent(this._coreView.pages.currentPage.getSlideContentID(route.deeplink));
                onPageUpdated();
            })
        } else {
            onPageUpdated();
        }
    }

    private updateArrowNavigation() {
        this._coreView.arrowNavigation.upArrow.enabled = this._coreView.pages.hasPrevPage;
        this._coreView.arrowNavigation.downArrow.enabled = this._coreView.pages.hasNextPage;
        this._coreView.arrowNavigation.leftArrow.enabled = this._coreView.pages.currentPage && (this._coreView.pages.currentPage.hasPrevSlide || this._coreView.pages.currentPage.currentSlide.hasPrevContent);
        this._coreView.arrowNavigation.rightArrow.enabled = this._coreView.pages.currentPage && (this._coreView.pages.currentPage.hasNextSlide || this._coreView.pages.currentPage.currentSlide.hasNextContent);
    }

    private updateFocusMap() {
        let pageID = this._coreView.pages.currentPageID;
        let pageSlideID = this._coreView.pages.currentPage ? this._coreView.pages.currentPage.getSlideID(CoreRouter.CURRENT_DEEPLINK) : 0;
        this._coreView.focusMap.setPointer(pageID, pageSlideID);
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onSiteDataLoaded() {
        this._coreRouter.sitemap = this._coreData.sitemapModel;
        this._coreView.initNavigation(this._coreData.menuModel, this._coreData.sitemapModel);
        this._coreView.pages.setModels(this._coreData.pageModels);
        this.initListener();
        let route = this._coreRouter.getRouteFromDeeplink();
        if (route.deeplink.indexOf(AppConfig.SHOW_MENU_SLIDE_DEEPLINK) != -1) {
            route = this._coreRouter.getHomepageRoute();
            this._coreRouter.setHomepageState();
        }
        this.onRouteSignal(route, true);
        if (route.deeplink != this._coreData.imprintPrivacyDeeplink) {
            this._coreView.arrowNavigation.playIntro();
            this._coreView.focusMap.playIntro();
        }
    }

    private onDown() {
        this._coreRouter.addState(this._coreData.sitemapModel.getNextPageRoute());
    }

    private onUp() {
        this._coreRouter.addState(this._coreData.sitemapModel.getPrevPageRoute());
    }

    private onLeft() {
        if (this._coreView.pages.currentPage.currentSlide.hasPrevContent) {
            this._coreRouter.addState(this._coreView.pages.currentPage.currentSlide.getPrevContentRoute());
        } else {
            this._coreRouter.addState(this._coreData.sitemapModel.getPrevPageSlideRoute());
        }
    }

    private onRight() {
        if (this._coreView.pages.currentPage.currentSlide.hasNextContent) {
            this._coreRouter.addState(this._coreView.pages.currentPage.currentSlide.getNextContentRoute());
        } else {
            this._coreRouter.addState(this._coreData.sitemapModel.getNextPageSlideRoute());
        }
    }

    private onDeeplinkClicked(deeplink: string) {
        this._coreRouter.addState(deeplink);
    }

    private onRouteSignal(route: IRouteVO, isInital: boolean = false) {
        if (route.deeplink == this._coreData.imprintPrivacyDeeplink) {
            this._coreView.metaLayer.show();
        } else {
            this.updateMenu();
            this._coreView.metaLayer.hide();
            this.updatePage(route, isInital, () => {
                this.updateArrowNavigation();
                this.updateFocusMap();
            });
        }
    }

    private onShowMetaLayer() {
        this._coreView.arrowNavigation.hide();
        this._coreView.focusMap.hide();
        this._coreView.menu.hide();
        this._coreView.pages.hide();
        this._coreView.metaNavigation.hide();
    }

    private onHideMetaLayer() {
        this.updateArrowNavigation();
        this._coreView.arrowNavigation.show();
        this._coreView.focusMap.show();
        this._coreView.menu.show();
        this._coreView.pages.show();
        this._coreView.metaNavigation.show();
    }

    private onHideMenu() {
        if (!this._coreView.menu.useVerticalMenu) return;
        TweenMax.delayedCall(0.5, () => {
            this._coreView.arrowNavigation.show();
            this._coreView.pages.show();
            this._coreView.focusMap.show();
            this._coreView.metaNavigation.show();
        })
    }

    private onShowMenu() {
        if (!this._coreView.menu.useVerticalMenu) return;
        this._coreView.arrowNavigation.hide();
        this._coreView.pages.hide();
        this._coreView.focusMap.hide();
        this._coreView.metaNavigation.hide();
    }

    private onShowMenuSlideReached() {
        this._coreView.menu.moveToCenter();
        this._coreView.pages.hide();
    }

    private onShowMenuSlideLeft() {
        this._coreView.pages.show();
    }
}
