/******************************************************************
 * AppConfig
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AppConfig {

    /* ----------------------------------------------------------------
 	 * APP PATHES
 	 * --------------------------------------------------------------*/

    static get API_URL(): string {
        switch (location.href.split("//")[1].split("/")[0]) {
            case "localhost:9011":
            case "localhost":
                return "http://localhost/grottker/grottker-website/www/api";
            case "192.168.2.108:9011":
            case "192.168.2.108":
                return "http://192.168.2.108/grottker/grottker-website/www/api";
            default:
                return AppConfig.APP_BASE_PATH + "api";
        }
    }

    static get APP_BASE_PATH(): string {
        let protocoll = "http://";
        if (location.protocol == 'https:') {
            protocoll = "https://";
        }
        switch (location.href.split("//")[1].split("/")[0]) {
            case "localhost:9011":
                return "http://localhost:9011/";
            case "localhost":
                return protocoll + "localhost/grottker/grottker-website/www/";
            case "192.168.2.108:9011":
                return protocoll + "192.168.2.108:9011/";
            case "192.168.2.108":
                return protocoll + "192.168.2.108/";
            case "relaunch.katharinagrottker.de":
                if (location.href.indexOf("develop") != -1) {
                    return protocoll + "relaunch.katharinagrottker.de/_develop/";
                }
                return protocoll + "relaunch.katharinagrottker.de/";
            case "www.katharinagrottker.de":
                return protocoll + "www.katharinagrottker.de/";
            case "katharinagrottker.de":
                if (location.href.indexOf("_develop") != -1) {
                    return protocoll + "katharinagrottker.de/_develop/";
                }
                return protocoll + "katharinagrottker.de/";
            case "develop.katharinagrottker.de":
                return protocoll + "develop.katharinagrottker.de/";
        }
    }

    /* ----------------------------------------------------------------
 	 * ANIMATION SETTINGS
 	 * --------------------------------------------------------------*/

    static SHOW_UI_DELAY = 1;
    static SHOW_TEXT_SHADOWS = false;
    static ANIMATE_BACKGROUND_CIRCLES = false;
    static CHANGE_PAGE_DURATION = 1;
    static META_LAYER_DURATION = 0.7;


    /* ----------------------------------------------------------------
 	 * MENU SETTINGS
 	 * --------------------------------------------------------------*/

    static AUTO_SHOW_MENU_WHEN_CENTERED = true;
    static AUTO_SHOW_MENU_WHEN_CENTERED_FIRST_DURATION = 3;
    static AUTO_SHOW_MENU_WHEN_CENTERED_DURATION = 2;
    static SHOW_MENU_SLIDE_DEEPLINK = "/end";

    /* ----------------------------------------------------------------
 	 * PHOTO STORY SETTINGS
 	 * --------------------------------------------------------------*/

    static PHOTO_STORY_BACKGROUND_SCALE = 0.6;
}
