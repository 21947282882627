import {UIView} from "../../../../../../@jashson/core-ui/UIView";
import * as signals from "signals";
import {IRouteVO} from "../../../../../vo/IRouteVO";

/******************************************************************
 * AbstractPageSlideContent
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AbstractPageSlideContent extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    protected _horizontalProgress: number = 0; // could be -1 (left) ... 0 (center) ... 1 (right)
    protected _verticalProgress: number = 0; // could be -1 (top) ... 0 (center) ... 1 (bottom)

    protected _hasHorizontalCenterReached = false;
    protected _hasHorizontalCenterLeft = false;
    protected _hasHorizontalCenterEntered = false;
    protected _hasHorizontalCenterHidden = false;

    protected _currentContentID: number = 0;

    protected onHorizontalCenterEnterSignal = new signals.Signal();
    protected onHorizontalCenterReachSignal = new signals.Signal();
    protected onHorizontalCenterLeaveSignal = new signals.Signal();
    protected onHorizontalCenterHideSignal = new signals.Signal();

    public onNextSlideSignal = new signals.Signal();
    public onPrevSlideSignal = new signals.Signal();
    public onContentChangeSignal = new signals.Signal();
    public onShowMenuSignal = new signals.Signal();
    public onHideMenuSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(type: string, protected _isFirstSlide: boolean = false, protected _contentDeeplinks: string[] = null) {
        super(type)
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get currentContentID(): number {
        return this._currentContentID;
    }

    get horizontalProgress(): number {
        return this._horizontalProgress;
    }

    get verticalProgress(): number {
        return this._verticalProgress;
    }

    set verticalProgress(value: number) {
        this._verticalProgress = value;
        this.updatePositionProgress();
    }

    set horizontalProgress(value: number) {
        let isLeavingToLeft = this._horizontalProgress > value && value < 0;
        let isLeavingToRight = this._horizontalProgress < value && value > 0 && value < 1;
        let isEnteringFromLeft = this._horizontalProgress < value && value > -1 && value < 0;
        let isEnteringFromRight = this._horizontalProgress > value && value < 1 && value > 0;
        this._horizontalProgress = value;
        if (this._horizontalProgress == 0 && !this._hasHorizontalCenterReached) {
            this._hasHorizontalCenterReached = true;
            this._hasHorizontalCenterHidden = false;
            this._hasHorizontalCenterLeft = false;
            this.onHorizontalCenterReachSignal.dispatch();
        }
        if (Math.abs(this._horizontalProgress) == 1 && !this._hasHorizontalCenterHidden) {
            this._hasHorizontalCenterHidden = true;
            this._hasHorizontalCenterEntered = false;
            this._hasHorizontalCenterReached = false;
            this.onHorizontalCenterHideSignal.dispatch();
        }
        if ((isLeavingToLeft || isLeavingToRight) && !this._hasHorizontalCenterLeft) {
            this._hasHorizontalCenterLeft = true;
            this._hasHorizontalCenterEntered = false;
            this._hasHorizontalCenterHidden = false;
            this._hasHorizontalCenterReached = false;
            this.onHorizontalCenterLeaveSignal.dispatch();
        }
        if ((isEnteringFromLeft || isEnteringFromRight) && !this._hasHorizontalCenterEntered) {
            this._hasHorizontalCenterEntered = true;
            this._hasHorizontalCenterLeft = false;
            this._hasHorizontalCenterHidden = false;
            this._hasHorizontalCenterReached = false;
            this.onHorizontalCenterEnterSignal.dispatch();
        }
        this.updatePositionProgress();
    }

    get hasNextContent(): boolean {
        return false;
    }

    get hasPrevContent(): boolean {
        return false;
    }

    public prepareContent() {
        // this methode is called if the previous slide is getting centered
    }

    public getNextContentRoute(): IRouteVO {
        return undefined;
    }

    public getPrevContentRoute(): IRouteVO {
        return undefined;
    }

    public showContent(contentID: number) {
        this._currentContentID = contentID;
        this.updateStyles();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    protected updatePositionProgress() {

    }

    /******************************************************************
     * Events
     *****************************************************************/

}
