import * as signals from "signals";
import {IRouteVO} from "../vo/IRouteVO";
import {AppConfig} from "../config/AppConfig";
import {SitemapModel} from "../model/SitemapModel";
import {GenericTextModel} from "../model/GenericTextModel";

/******************************************************************
 * CoreRouter
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CoreRouter {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _sitemap: SitemapModel;

    public onRouteSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        this.initListener();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    set sitemap(model: SitemapModel) {
        this._sitemap = model;
    }

    get isHomepage(): boolean {
        return this._sitemap.isCurrentDeeplinkHomePage;
    }

    static get CURRENT_DEEPLINK(): string {
        let deepLinkBase = document.location.href.split(AppConfig.APP_BASE_PATH)[1];
        deepLinkBase = deepLinkBase.split("?")[0];
        let deepLink = deepLinkBase;
        deepLinkBase.length > 1 ? deepLink = deepLinkBase.split('.html')[0] : '';
        return deepLink;
    }

    public getRouteFromDeeplink(): IRouteVO {
        let routeVO = this._sitemap.getRoute(CoreRouter.CURRENT_DEEPLINK);
        if (!routeVO) {
            routeVO = this.getHomepageRoute();
        }
        this.setDocumentTitle(GenericTextModel.APP_TITLE);
        return routeVO;
    }

    public getHomepageRoute(): IRouteVO {
        return {
            deeplink: ""
        };
    }

    public setHomepageState() {
        history.replaceState(this.getHomepageRoute(), GenericTextModel.APP_TITLE, AppConfig.APP_BASE_PATH)
    }

    public addState(deeplink: string | IRouteVO) {
        let routeVO = null;
        if (typeof deeplink == "string") {
            routeVO = this._sitemap.getRoute(deeplink);
        } else {
            routeVO = deeplink;
        }
        let pageTitle = GenericTextModel.APP_TITLE;
        history.pushState(routeVO, pageTitle, AppConfig.APP_BASE_PATH + routeVO.deeplink);
        this.setDocumentTitle(pageTitle);
        this.popStateEvent(routeVO);
    }

    private setDocumentTitle(title: string) {
        document.title = title;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initListener() {
        window.onpopstate = () => {
            this.popStateEvent(this.getRouteFromDeeplink());
        };
    }

    private popStateEvent(routeSignal: IRouteVO) {
        this.setDocumentTitle(GenericTextModel.APP_TITLE);
        this.onRouteSignal.dispatch(routeSignal);
    }

    /******************************************************************
     * Events
     *****************************************************************/
}
