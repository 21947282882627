import {CoreView} from "./core/CoreView";
import {CoreData} from "./core/CoreData";
import {CoreController} from "./core/CoreController";
import {CoreRouter} from "./core/CoreRouter";
import 'core-js/es6/array';

/******************************************************************
 * Grottker Website Main
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class GrottkerWebsiteMain {

    /******************************************************************
     * Properties
     *****************************************************************/

    private readonly _coreView: CoreView;
    private readonly _coreData: CoreData;
    private readonly _coreController: CoreController;
    private readonly _coreRouter: CoreRouter;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        this._coreData = new CoreData();
        this._coreView = new CoreView();
        this._coreRouter = new CoreRouter();
        this._coreController = new CoreController(this._coreView, this._coreData, this._coreRouter);
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public get view(): HTMLElement {
        return this._coreView.view;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/


    /******************************************************************
     * Events
     *****************************************************************/

}