import {UIView} from "../../../../@jashson/core-ui/UIView";
import {ImageVO} from "../../../vo/ImageVO";
import * as signals from "signals";
import {Sine, TweenMax} from "gsap";
import {ProgressCircles} from "./ProgressCircles";
import {BackgroundImage} from "./BackgroundImage";

/******************************************************************
 * ImageComponent
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ImageComponent extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private static readonly USE_FILTERS = false;

    private static IMAGE_BLOB_STORE: {url: string, blob: Blob}[] = [];

    private _image: BackgroundImage;
    private _imageVO: ImageVO;
    private _httpRequest: XMLHttpRequest;
    private _progressCircles: ProgressCircles;

    public onImageLoadedSignal = new signals.Signal();
    public onImageProgressSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("image-component");
        this.initImage();
        this.initProgressCircles();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get imageVO(): ImageVO {
        return this._imageVO;
    }

    set useSmallSizeProgressCircles(value: boolean) {
        this._progressCircles.smallSize = value;
    }

    public load(imageVO: ImageVO, loadThumbnail: boolean = false) {
        let storedBlob = this.getBlobFromStore(loadThumbnail ? imageVO.thumbnailURL : imageVO.url);
        this.unload(() => {
            if (storedBlob) {
                this.showImage(storedBlob, 0);
            } else {
                this._httpRequest.send();
            }
        });
        this._imageVO = imageVO;
        if (storedBlob){
            return;
        }
        this._progressCircles.show();
        this._httpRequest = new XMLHttpRequest();
        this._httpRequest.open('GET', loadThumbnail ? imageVO.thumbnailURL : imageVO.url, true);
        this._httpRequest.responseType = 'arraybuffer';
        this._httpRequest.onload = () => {
            let blob = new Blob([this._httpRequest.response]);
            this.saveBlob(loadThumbnail ? imageVO.thumbnailURL : imageVO.url, blob);
            this.showImage(blob, 0.5);
            this.onImageLoadedSignal.dispatch();
        };
        this._httpRequest.onprogress = (e) => {
            this.onImageProgressSignal.dispatch((e.loaded / e.total));
        };
        this._httpRequest.onloadstart = () => {
            this.onImageProgressSignal.dispatch(0);
        };
    }

    public unload(callback: () => any = null) {
        this._progressCircles.hide();
        if (this._httpRequest) {
            this._httpRequest.abort();
            this._httpRequest = null;
        }
        TweenMax.to(this._image.view, 0.5, {
            alpha: 0,
            ease: Sine.easeInOut,
            filter: ImageComponent.USE_FILTERS ? "grayscale(100%)" : "none",
            onComplete: () => {
                if (callback) callback();
            }
        });
    }

    public updateStyles() {
        this._image.applyStyle({
            width: "100%",
            height: "100%"
        });
        this._progressCircles.applyStyle({
            position: "absolute",
            top: "50%",
            left: "50%",
            x: "-50%",
            y: "-50%"
        });
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initImage() {
        this._image = new BackgroundImage();
        this._image.size = BackgroundImage.SIZE_COVER;
        this.addChild(this._image);
    }

    private initProgressCircles() {
        this._progressCircles = new ProgressCircles();
        this._progressCircles.hide(true);
        this.addChild(this._progressCircles);
    }

    private showImage(blob: Blob, delay: number) {
        this._image.imageBlob = blob;
        TweenMax.to(this._image.view, 1, {
            startAt: {
                filter: ImageComponent.USE_FILTERS ? "grayscale(100%)" : "none",
            },
            delay: delay,
            alpha: 1,
            filter: ImageComponent.USE_FILTERS ? "grayscale(0%)" : "none",
            ease: Sine.easeInOut,
            onStart: () => {
                this._progressCircles.hide();
            }
        });
    }

    private getBlobFromStore(url: string): Blob {
        let blob: Blob = undefined;
        ImageComponent.IMAGE_BLOB_STORE.forEach((storeItem) => {
            if (storeItem.url == url) {
                blob = storeItem.blob;
            }
        });
        return blob;
    }

    private saveBlob(url: string, blob: Blob) {
        ImageComponent.IMAGE_BLOB_STORE.push({url: url, blob: blob});
    }

    /******************************************************************
     * Events
     *****************************************************************/

}
