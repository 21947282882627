/******************************************************************
 * DOMUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class DOMUtils {

    static isMouseInRect(rect: ClientRect, mouseX: number, mouseY: number): boolean {
        return rect.left <= mouseX && mouseX <= rect.left + rect.width &&
            rect.top <= mouseY && mouseY <= rect.top + rect.height;
    }

    static downloadFile(url: string, filename: string) {
        let anchorElement = document.createElement('a');
        document.body.appendChild(anchorElement);
        anchorElement.download = filename;
        anchorElement.href = url;
        anchorElement.click();
        anchorElement.parentElement.removeChild(anchorElement);
    }
}
