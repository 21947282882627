import {UIView} from "../../../../../../../../@jashson/core-ui/UIView";
import {ImageVO} from "../../../../../../../vo/ImageVO";
import {Thumbnail} from "./Thumbnail";
import * as signals from "signals";
import {TweenMax} from "gsap";
import {SizeAndPosition} from "../../../../../../../style/SizeAndPosition";

/******************************************************************
 * Thumbnails
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Thumbnails extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _thumbnailsLoaded = false;
    private _currentSelectedImageVO: ImageVO;

    public onClickSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _imageVOs: ImageVO[]) {
        super("thumbnails");
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    set selectedImageVO(imageVO: ImageVO) {
        this._currentSelectedImageVO = imageVO;
        this.children.forEach((thumbnail: Thumbnail) => {
            thumbnail.selected = thumbnail.imageVO == imageVO;
        });
    }

    public show() {
        if (!this._thumbnailsLoaded) {
            this.initThumbnails();
            this._thumbnailsLoaded = true;
        }
        TweenMax.to(this.view, 1, {
            autoAlpha: 1
        })
    }

    public hide(instantly: boolean = false) {
        TweenMax.to(this.view, instantly ? 0 : 0.1, {
            autoAlpha: 0
        })
    }

    public updateStyles() {
        this.applyStyle({
            whiteSpace: "nowrap"
        });
        this.children.forEach((thumbnail: Thumbnail, i: number) => {
            thumbnail.applyStyle({
                display: "inline-block",
                marginLeft: i == 0 ? 0 : SizeAndPosition.THUMBNAIL_MARGIN
            });
        });
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initThumbnails() {
        this._imageVOs.forEach((imageVO: ImageVO) => {
            let thumbnail = new Thumbnail(imageVO);
            thumbnail.onClickSignal.add(() => this.onThumbnailClicked(imageVO));
            this.addChild(thumbnail);
        });
        this.selectedImageVO = this._currentSelectedImageVO;
        this.updateStyles();
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onThumbnailClicked(imageVO: ImageVO) {
        this.onClickSignal.dispatch(imageVO);
    }
}
