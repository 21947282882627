import {UIView} from "../../../../../@jashson/core-ui/UIView";
import {LabelButton} from "../../basic/LabelButton";
import {GenericTextModel} from "../../../../model/GenericTextModel";
import {SizeAndPosition} from "../../../../style/SizeAndPosition";
import * as signals from "signals";
import {TweenMax, Power4} from "gsap";
import {AppConfig} from "../../../../config/AppConfig";

/******************************************************************
 * MetaNavigation
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class MetaNavigation extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _labelButton: LabelButton;

    public onClickSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("meta-navigation");
        this.initLabelButton();
        this.applyStyle({
            display: "none"
        })
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public show() {
        this.interactive = true;
        TweenMax.to(this.view, AppConfig.META_LAYER_DURATION, {
            y: "0%",
            ease: Power4.easeOut
        })
    }

    public hide() {
        this.interactive = false;
        TweenMax.to(this.view, AppConfig.META_LAYER_DURATION, {
            y: "400%",
            ease: Power4.easeOut
        })
    }

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            display: "inline-block",
            right: SizeAndPosition.APP_UI_MARGIN,
            bottom: SizeAndPosition.APP_UI_MARGIN,
            alpha: 0.5,
            fontSize: 0
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/


    /******************************************************************
     * Events
     *****************************************************************/

    private initLabelButton() {
        this._labelButton = new LabelButton(GenericTextModel.IMPRINT_PRIVACY_TITLE.toUpperCase());
        this._labelButton.onClickSignal.add(() => this.onClickSignal.dispatch());
        this.addChild(this._labelButton);
    }
}
