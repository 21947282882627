import {UIView} from "../../../../@jashson/core-ui/UIView";
import * as signals from "signals";
import {TweenMax, Power4} from "gsap";
import {Color} from "../../../style/Color";
import {ShadowStyle} from "../../../style/ShadowStyle";
import {FontStyle} from "../../../style/FontStyle";
import {SizeAndPosition} from "../../../style/SizeAndPosition";

/******************************************************************
 * LabelButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class LabelButton extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _labelField: UIView<HTMLDivElement>;
    private _showFrame: boolean;

    public onClickSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _label: string) {
        super("label-button");
        this.initLabelField();
        this.initListeners();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public set showFrame(value: boolean) {
        this._showFrame = value;
        this.updateStyles();
    }

    public updateStyles() {
        this.applyStyle({
            display: "inline-block"
        });
        this._labelField.applyStyle({
            cursor: "pointer",
            color: Color.GREY,
            fontWeight: FontStyle.FONT_WEIGHT_MEDIUM,
            fontSize: this._showFrame ? FontStyle.FONT_SIZE_HEADER_TITLE : FontStyle.FONT_SIZE_LABELBUTTON,
            padding: this._showFrame ? (SizeAndPosition.IS_SMALL_SCREEN ? "10px 20px" : "15px 30px") : "0",
            borderRadius: this._showFrame ? 7 : 0,
            border: this._showFrame ? "1px solid " + Color.GREY : "unset",
            boxShadow: this._showFrame && !SizeAndPosition.IS_SMALL_SCREEN ? ShadowStyle.SMALL_SHADOW : "none",
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initLabelField() {
        this._labelField = new UIView("label");
        this._labelField.view.innerHTML = this._label.toUpperCase();
        this.addChild(this._labelField);
    }

    private initListeners() {
        this._labelField.view.addEventListener("click", () => this.onClicked());
        this._labelField.view.addEventListener("mouseover", () => this.onMouseOver());
        this._labelField.view.addEventListener("mouseout", () => this.onMouseOut());
    }

    private highlight(value: boolean) {
        TweenMax.to(this._labelField.view, 0.3, {
            color: value ? Color.WHITE : Color.GREY,
            textShadow: value ? ShadowStyle.STANDARD_SHADOW : ShadowStyle.SMALL_SHADOW,
            boxShadow: this._showFrame && !SizeAndPosition.IS_SMALL_SCREEN ? (value ? ShadowStyle.STANDARD_SHADOW : ShadowStyle.SMALL_SHADOW) : "none",
            ease: Power4.easeOut
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onClicked() {
        this.onClickSignal.dispatch();
        this.highlight(false);
    }

    private onMouseOver() {
        this.highlight(true);
    }

    private onMouseOut() {
        this.highlight(false);

    }
}
