import {UIView} from "../../../../@jashson/core-ui/UIView";
import {SVGView} from "../../../../@jashson/core-svg/SVGView";
import {TweenMax, Power1} from "gsap";
import {SizeAndPosition} from "../../../style/SizeAndPosition";
import {DeviceUtils} from "../../../../@jashson/core-utils/DeviceUtils";

/******************************************************************
 * ProgressCircles
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ProgressCircles extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _circlesSVG: SVGView;
    private _circleBox: SVGElement;
    private _circleElements: SVGElement[];
    private _isShowing: boolean = true;
    private _smallSize: boolean = false;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("progress-circles");
        this.initCircles();
        this.initElements();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    set smallSize(value: boolean) {
        this._smallSize = value;
        this.updateStyles();
    }

    public updateStyles() {
        let size = this._smallSize ? 30 : 70;
        size *= SizeAndPosition.IS_SMALL_SCREEN ? 0.5 : 1;
        this.applyStyle({
            width: size,
            height: size
        });
        TweenMax.set(this._circleBox, {
            transformOrigin: "50% 50%"
        });
        this._circlesSVG.applyStyle({
            position: "absolute",
            width: size,
            height: size
        });
        this._circlesSVG.width = size;
        this._circlesSVG.height = size;
    }

    public show() {
        if (this._isShowing) return;
        this._isShowing = true;
        TweenMax.to(this.view, 1, {
            scale: 1,
            autoAlpha: 1
        });
        TweenMax.to(this._circleBox, 1, {
            startAt: {
                rotation: 0
            },
            rotation: 360,
            repeat: -1,
            ease: Power1.easeInOut
        });
        this._circleElements.forEach((circle: SVGElement) => {
            play();
            function play() {
                TweenMax.to(circle, 1, {
                    bezier: [
                        {
                            x: Math.random() * 60 - 30,
                            y: Math.random() * 60 - 30
                        },
                        {
                            x: 0,
                            y: 0
                        }
                    ],
                    ease: Power1.easeInOut,
                    onComplete: () => {
                        play();
                    }
                })
            }
        })
    }

    public hide(instantly: boolean = false) {
        if (!this._isShowing) return;
        this._isShowing = false;
        TweenMax.to(this.view, instantly ? 0 : 1, {
            scale: 0,
            autoAlpha: 0,
            onComplete: () => {
                this._circleElements.forEach((circle: SVGElement) => {
                    TweenMax.killTweensOf(circle);
                });
                TweenMax.killTweensOf(this._circleBox);
            }
        });


    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initCircles() {
        this._circlesSVG = new SVGView(require("ASSETS/images/circles-logo.svg"));
        this._circlesSVG.clearSizeDefinition();
        this.addChild(this._circlesSVG);
    }

    private initElements() {
        this._circleBox = this._circlesSVG.getElementByID("circles-logo");
        this._circleElements = Array.from(this._circlesSVG.getElementByID("circles").childNodes) as SVGElement[];
        TweenMax.set(this._circlesSVG.getElementByID("viewbox"), {
            autoAlpha: 0
        });
        TweenMax.set(this._circleElements, {
            stroke: "rgba(255, 255, 255, 0.25)",
            strokeWidth: 1,
            fill: "rgba(255, 255, 255, 0)"
        });
        if (!DeviceUtils.IS_IE_OR_EDGE) {
            TweenMax.set(this._circleElements, {
                vectorEffect: "non-scaling-stroke"
            });
        }
    }

    /******************************************************************
     * Events
     *****************************************************************/

}
