import {UIView} from "../../../../../@jashson/core-ui/UIView";
import {PageModel} from "./PageModel";
import {Page} from "./Page";
import {Power4, TweenMax} from "gsap";
import {SizeAndPosition} from "../../../../style/SizeAndPosition";
import {AppConfig} from "../../../../config/AppConfig";
import * as signals from "signals";

/******************************************************************
 * Pages
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Pages extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _currentPageID: number = undefined;
    private _pageModels: PageModel[];
    private _pageStore = {};

    public onNextSlideSignal = new signals.Signal();
    public onPrevSlideSignal = new signals.Signal();
    public onContentChangeSignal = new signals.Signal();
    public onShowMenuSignal = new signals.Signal();
    public onHideMenuSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("pages");
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get currentPage(): Page {
        if (this._currentPageID == undefined) return undefined;
        return this.children[this.children.length - 1] as Page;
    }

    get hasNextPage(): boolean {
        if (this._currentPageID == undefined && this._pageModels.length > 0) return true;
        return this._currentPageID < this._pageModels.length - 1;
    }

    get hasPrevPage(): boolean {
        return this._currentPageID != undefined;
    }

    get currentPageID(): number {
        return this._currentPageID;
    }

    public getPageID(deeplink: string) {
        let pageID = undefined;
        this._pageModels.forEach((model: PageModel, i: number) => {
            if (deeplink.indexOf(model.deeplink) != -1) {
                pageID = i;
            }
        });
        return pageID;
    }

    public show() {
        this.interactive = true;
        TweenMax.to(this.view, AppConfig.META_LAYER_DURATION, {
            autoAlpha: 1
        })
    }

    public hide() {
        this.interactive = false;
        TweenMax.to(this.view, AppConfig.META_LAYER_DURATION, {
            autoAlpha: 0
        })
    }

    public setModels(pageModels: PageModel[]) {
        this._pageModels = pageModels;
    }

    public showPage(id: number, isInital: boolean) {
        let isTweening = false;
        if (this._pageStore[this._currentPageID]) {
            isTweening = this._currentPageID != undefined && TweenMax.isTweening(this._pageStore[this._currentPageID].view);
        }
        if (id < 0 || id == undefined) {
            this.hidePage(this._currentPageID ? this._currentPageID : 0, false, false);
            this._currentPageID = undefined;
            return;
        }
        if (id == this._currentPageID) {
            return;
        }
        if (id > this._pageModels.length - 1) {
            return;
        }
        let isNext = id > this._currentPageID || this._currentPageID == undefined;
        this.hidePage(this._currentPageID, isNext, isTweening);
        this._currentPageID = id;
        let page = this._pageStore[id] as Page;
        if (!page) {
            page = new Page(this._pageModels[id]);
            this._pageStore[id] = page;
        }
        page.onNextSlideSignal.add(() => this.onNextSlideSignal.dispatch());
        page.onPrevSlideSignal.add(() => this.onPrevSlideSignal.dispatch());
        page.onContentChangeSignal.add((deeplink) => this.onContentChangeSignal.dispatch(deeplink));
        page.onShowMenuSignal.add(() => this.onShowMenuSignal.dispatch());
        page.onHideMenuSignal.add(() => this.onHideMenuSignal.dispatch());
        TweenMax.killTweensOf(page.view);
        TweenMax.killTweensOf(page);
        TweenMax.to(page.view, isInital ? 0 : AppConfig.CHANGE_PAGE_DURATION, {
            startAt: {
                y: (isNext ? 1 : -1) * SizeAndPosition.STAGE_HEIGHT
            },
            y: 0,
            ease: isTweening ? Power4.easeOut : Power4.easeInOut,
            onComplete: () => {
                page.updateSliderStyles();
            }
        });
        TweenMax.to(page, isInital ? 0 : AppConfig.CHANGE_PAGE_DURATION, {
            startAt: {
                verticalProgress: isNext ? 1 : -1
            },
            verticalProgress: 0,
            ease: isTweening ? Power4.easeOut : Power4.easeInOut
        });
        this.addChild(page);
    }

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            top: 0,
            left: 0,
            width: SizeAndPosition.STAGE_WIDTH,
            height: SizeAndPosition.STAGE_HEIGHT,
            overflow: "hidden"
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private hidePage(pageID: number, moveUp: boolean, isTweening: boolean) {
        let page: Page = this._pageStore[pageID];
        if (!page) return;
        page.onNextSlideSignal.removeAll();
        page.onPrevSlideSignal.removeAll();
        page.onContentChangeSignal.removeAll();
        page.onShowMenuSignal.removeAll();
        page.onHideMenuSignal.removeAll();
        TweenMax.killTweensOf(page.view);
        TweenMax.killTweensOf(page);
        TweenMax.to(page, AppConfig.CHANGE_PAGE_DURATION, {
            verticalProgress: moveUp ? -1 : 1,
            ease: isTweening ? Power4.easeOut : Power4.easeInOut,
        });
        TweenMax.to(page.view, AppConfig.CHANGE_PAGE_DURATION, {
            y: (moveUp ? -1 : 1) * SizeAndPosition.STAGE_HEIGHT,
            ease: isTweening ? Power4.easeOut : Power4.easeInOut,
            onComplete: () => {
                this.removeChild(page);
            }
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/


}
