/******************************************************************
 * Color
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Color {
    static WHITE = "#FFFFFF";
    static GREY = "#BCBCBC";
    static DARK_GREY = "#707173";
    static LIGHT_BLACK = "#333333";
    static DARK_BLACK = "#111111";
    static RED = "#F7403A";

    static convertHEXtoRGBAString(hexColor: string, alpha: number = 1) {
        let rgbArray = Color.convertHEXtoRGBArray(hexColor);
        return "rgba(" + rgbArray[0] + ", " + rgbArray[1] + ", " + rgbArray[2] + ", " + alpha + ")";
    }

    // https://codepen.io/njmcode/pen/axoyD?editors=0010
    static calcColorBetween(hexColor1: string, hexColor2: string, progress: number): string {
        let colorArray1 = Color.convertHEXtoRGBArray(hexColor1);
        let colorArray2 = Color.convertHEXtoRGBArray(hexColor2);
        let result = colorArray1.slice();
        for (let i = 0; i < 3; i++) {
            result[i] = Math.round(result[i] + progress * (colorArray2[i] - colorArray1[i]));
        }
        return Color.convertRGBArrayToHEX(result);
    }

    // https://codepen.io/njmcode/pen/axoyD?editors=0010
    static convertHEXtoRGBArray(hexColor: string): number[] {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : null;
    }

    // https://codepen.io/njmcode/pen/axoyD?editors=0010
    static convertRGBArrayToHEX(colorArray: number[]): string {
        return "#" + ((1 << 24) + (colorArray[0] << 16) + (colorArray[1] << 8) + colorArray[2]).toString(16).slice(1);
    }
}
