import * as signals from "signals";
import {RequestUtils} from "../../@jashson/core-utils/RequestUtils";
import {AppConfig} from "../config/AppConfig";
import {PageModel} from "../ui/components/complex/pages/PageModel";
import {MenuModel} from "../ui/components/complex/menu/MenuModel";
import {GenericTextModel} from "../model/GenericTextModel";
import {SitemapModel} from "../model/SitemapModel";

/******************************************************************
 * CoreData
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CoreData {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _menuModel: MenuModel;
    private _pageModels: PageModel[];
    private _sitemapModel: SitemapModel;
    private _siteDataJSON: any;

    public onSiteDataLoadSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get menuModel(): MenuModel {
        return this._menuModel;
    }

    get pageModels(): PageModel[] {
        return this._pageModels;
    }

    get sitemapModel(): SitemapModel {
        return this._sitemapModel;
    }

    get imprintPrivacyDeeplink(): string {
        return this._siteDataJSON.meta.imprintPrivacyDeeplink;
    }

    public loadSiteData() {
        RequestUtils.getURL(AppConfig.API_URL + "/sitedata", (result) => {
            this._siteDataJSON = JSON.parse(result);
            GenericTextModel.init(this._siteDataJSON.genericTexts);
            this._menuModel = new MenuModel(this._siteDataJSON);
            this._pageModels = this.createPageModels(this._siteDataJSON);
            this._sitemapModel = new SitemapModel(this._pageModels, this.imprintPrivacyDeeplink);
            this.onSiteDataLoadSignal.dispatch();
        });
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private createPageModels(pageJsons: any): PageModel[] {
        let pageModels: PageModel[] = [];
        pageJsons.pages.forEach((pageJson: any) => {
            pageModels.push(new PageModel(pageJson));
        });
        return pageModels;
    }

    /******************************************************************
     * Events
     *****************************************************************/



}
