import {AbstractPageSlideContent} from "../AbstractPageSlideContent";
import {ImageVO} from "../../../../../../vo/ImageVO";
import {MainImage} from "./mainimage/MainImage";
import {UIView} from "../../../../../../../@jashson/core-ui/UIView";
import {Color} from "../../../../../../style/Color";
import {FontStyle} from "../../../../../../style/FontStyle";
import {SizeAndPosition} from "../../../../../../style/SizeAndPosition";
import {Power4, TweenMax} from "gsap";
import {Thumbnails} from "./thumbnail/Thumbnails";
import {AppConfig} from "../../../../../../config/AppConfig";
import {LittleArrowButton} from "../../../../interface/LittleArrowButton";
import {IRouteVO} from "../../../../../../vo/IRouteVO";

/******************************************************************
 * PhotoStory
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PhotoStory extends AbstractPageSlideContent {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _mainImage: MainImage;
    private _thumbnails: Thumbnails;
    private _interactionHelper: LittleArrowButton;
    private _captionField: UIView<HTMLDivElement>;
    private _captionFieldHeight = 0;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _imageVOs: ImageVO[], isFirstSlide: boolean, contentDeeplinks: string[]) {
        super("photo-story", isFirstSlide, contentDeeplinks);
        this.initMainImage();
        this.initThumbnails();
        this.initCaption();
        this.initInteractionHelper();
        this.onHorizontalCenterReachSignal.add(() => this.onForegroundReached());
        this.onHorizontalCenterLeaveSignal.add(() => this.onForegroundLeft());
        this.onHorizontalCenterEnterSignal.add(() => this.onForegroundEntered());
        this.onResizeSignal.add(() => this.onStageResized());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get hasNextContent(): boolean {
        return this.currentContentID < this._contentDeeplinks.length - 1;
    }

    get hasPrevContent(): boolean {
        return this.currentContentID > 0;
    }

    public getNextContentRoute(): IRouteVO {
        return {deeplink: this._contentDeeplinks[this.currentContentID + 1]};
    }

    public getPrevContentRoute(): IRouteVO {
        return {deeplink: this._contentDeeplinks[this.currentContentID - 1]};
    }

    public showContent(imageID: number) {
        this._currentContentID = imageID;
        this._mainImage.showImage(this._imageVOs[imageID]);
        this.setCaption(this._imageVOs[imageID].caption);
        if (this._thumbnails) {
            this._thumbnails.selectedImageVO = this._imageVOs[imageID];
        }
    }

    public prepareContent() {
        this._mainImage.loadImage(this._imageVOs[0]);
    }

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            width: SizeAndPosition.STAGE_WIDTH,
            height: SizeAndPosition.STAGE_HEIGHT,
            overflow: "hidden"
        });
        this._mainImage.applyStyle({
            position: "absolute",
            top: SizeAndPosition.USE_LANDSCAPE_MODE ? SizeAndPosition.LANDSCAPE_MODE_APP_MARGIN : SizeAndPosition.HEADER_HEIGHT,
            left: SizeAndPosition.USE_LANDSCAPE_MODE ? SizeAndPosition.LANDSCAPE_MODE_APP_MARGIN : SizeAndPosition.PREVIEW_MARGIN,
            width: SizeAndPosition.STAGE_WIDTH - (SizeAndPosition.USE_LANDSCAPE_MODE ? (2 * SizeAndPosition.LANDSCAPE_MODE_APP_MARGIN) : (2 * SizeAndPosition.PREVIEW_MARGIN)),
            height: SizeAndPosition.STAGE_HEIGHT - ((SizeAndPosition.USE_LANDSCAPE_MODE ? (2 * SizeAndPosition.LANDSCAPE_MODE_APP_MARGIN) : (SizeAndPosition.HEADER_HEIGHT + SizeAndPosition.FOOTER_HEIGHT + this._captionFieldHeight)))
        });
        this._captionField.applyStyle({
            position: "absolute",
            color: Color.GREY,
            fontSize: FontStyle.FONT_SIZE_CAPTION,
            fontWeight: FontStyle.FONT_WEIGHT_MEDIUM,
            letterSpacing: -1,
            textAlign: "center",
            left: this.getLocalX0(),
            top: this._mainImage.imageHeight + this.getLocalY0() + SizeAndPosition.THUMBNAIL_OFFSET_Y * (SizeAndPosition.IS_SMALL_SCREEN ? 1 : 2),
            width: this._mainImage.imageWidth
        });
        if (this._thumbnails) {
            this._thumbnails.applyStyle({
                position: "absolute",
                top: this._mainImage.imageHeight + this.getLocalY0() + SizeAndPosition.THUMBNAIL_OFFSET_Y,
                left: "50%",
                y: "-100%",
                x: "-50%"
            });
        }
        if (this._interactionHelper) {
            this._interactionHelper.applyStyle({
                position: "absolute",
                top: "50%",
                y: "-50%"
            });
        }
        this.updatePositionProgress();
    }

    /******************************************************************
     * Privsate Methodes
     *****************************************************************/

    private initMainImage() {
        this._mainImage = new MainImage();
        this._mainImage.interactive = true;
        this._mainImage.onImageLoadedSignal.add(() => this.updateStyles());
        this._mainImage.onMainImageResizeSignal.add(() => this.updateStyles());
        this._mainImage.onClickSignal.add(() => this.onMainImageClicked());
        this._mainImage.onAddedToStageSignal.addOnce(() => {
            this._mainImage.tweenToImageSize(this._imageVOs[this.currentContentID], this._hasHorizontalCenterReached ? 1 : AppConfig.PHOTO_STORY_BACKGROUND_SCALE);
        });
        this.addChild(this._mainImage);
    }

    private initThumbnails() {
        if (this._imageVOs.length <= 1) return;
        this._thumbnails = new Thumbnails(this._imageVOs);
        this._thumbnails.interactive = true;
        this._thumbnails.onClickSignal.add((imageVO: ImageVO) => this.onThumbnailClicked(imageVO));
        this._thumbnails.hide(true);
        this.addChild(this._thumbnails);
    }

    private initCaption() {
        this._captionField = new UIView("caption-field");
        this._captionField.view.innerHTML = "&nbsp;";
        this.addChild(this._captionField);
    }

    private initInteractionHelper() {
        if (!this._isFirstSlide) return;
        this._interactionHelper = new LittleArrowButton();
        this._interactionHelper.interactive = true;
        this._interactionHelper.onClickSignal.add(() => this.onInteractionHelperClicked());
        this._interactionHelper.onOverSignal.add(() => this.onInteractionHelperOver());
        this._interactionHelper.onOutSignal.add(() => this.onInteractionHelperOut());
        this.addChild(this._interactionHelper);
    }

    private setCaption(caption: string) {
        if (!caption || caption.length == 0) caption = "&nbsp;";
        if (caption == this._captionField.view.innerHTML) return;
        TweenMax.killTweensOf(this);
        TweenMax.killTweensOf(this._captionField.view);
        TweenMax.to(this._captionField.view, 0.5, {
            alpha: 0,
            ease: Power4.easeInOut,
            onComplete: () => {
                this._captionField.view.innerHTML = caption;
                TweenMax.to(this, 0.7, {
                    _captionFieldHeight: this.calcCaptionFieldHeight(),
                    ease: Power4.easeInOut,
                    onUpdate: () => {
                        this.updateStyles();
                    }
                });
                TweenMax.to(this._captionField.view, 0.7, {
                    delay: 1,
                    alpha: 1,
                    ease: Power4.easeOut,
                });
            }
        })

    }

    private getLocalY0(): number {
        return this._mainImage.imageClientY - this._view.getBoundingClientRect().top;
    }

    private getLocalX0() {
        return this._mainImage.imageClientX - this._view.getBoundingClientRect().left;
    }

    private calcCaptionFieldHeight(): number {
        if (this._captionField.view.offsetHeight == 0) {
            return 0;
        }
        return this._captionField.view.offsetHeight + SizeAndPosition.THUMBNAIL_OFFSET_Y * (SizeAndPosition.IS_SMALL_SCREEN ? 1 : 2);
    }

    protected updatePositionProgress() {
        let offsetX = -this._horizontalProgress * (this.getLocalX0() + SizeAndPosition.STAGE_WIDTH * 0.025);
        TweenMax.set(this.view, {
            x: offsetX
        });
        if (this._interactionHelper) {
            this._interactionHelper.applyStyle({
                left: this.getLocalX0(),
                x: SizeAndPosition.IS_SMALL_SCREEN ? "-150%" : "-120%"
            })
        }
    };

    private set showCaption(value: boolean) {
        TweenMax.to(this._captionField.view, AppConfig.CHANGE_PAGE_DURATION * 0.3, {
            autoAlpha: value && !SizeAndPosition.USE_LANDSCAPE_MODE ? 1 : 0
        })
    }

    private getImageDeeplink(imageVO: ImageVO) {
        let deeplink = this._contentDeeplinks[0];
        if (!imageVO) return deeplink;
        this._contentDeeplinks.forEach((contentDeeplink: string) => {
            if (contentDeeplink.split(imageVO.name)[0] + imageVO.name == contentDeeplink) {
                deeplink = contentDeeplink;
            }
        });
        return deeplink
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onThumbnailClicked(imageVO: ImageVO) {
        this.onContentChangeSignal.dispatch(this.getImageDeeplink(imageVO));
    }

    private onStageResized() {
        this.updateStyles();
        this._captionFieldHeight = this.calcCaptionFieldHeight();
        this.updateStyles();
    }

    private onForegroundReached() {
        this._mainImage.interactive = false;
        this.showCaption = true;
        if (this._interactionHelper) {
            this._interactionHelper.hide();
        }
        if (this._thumbnails) {
            this._thumbnails.show();
        }
    }

    private onForegroundLeft() {
        this._mainImage.tweenToImageSize(this._imageVOs[this.currentContentID], AppConfig.PHOTO_STORY_BACKGROUND_SCALE);
        this._mainImage.interactive = true;
        this._mainImage.isLeft = this._horizontalProgress < 0;
        this.showCaption = false;
        if (this._thumbnails) {
            this._thumbnails.hide();
        }
        if (this._interactionHelper && this._horizontalProgress > 0) {
            this._interactionHelper.show();
        }
    }

    private onForegroundEntered() {
        if (this._interactionHelper) {
            this._interactionHelper.hide();
        }
        this.showContent(this.currentContentID);
    }

    private onInteractionHelperClicked() {
        this.onNextSlideSignal.dispatch();
    }

    private onMainImageClicked() {
        this.onContentChangeSignal.dispatch(this.getImageDeeplink(this._mainImage.currentImageVO));
    }

    private onInteractionHelperOver() {
        this._mainImage.highlight = true;
    }

    private onInteractionHelperOut() {
        this._mainImage.highlight = false;
    }
}
