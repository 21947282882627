import * as signals from "signals";

/******************************************************************
 * SwipeGestures
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SwipeGestures {

	/******************************************************************
	 * Properties
	 *****************************************************************/

	private _touchStartX: number;
	private _touchStartY: number;
	private _mouseStartX: number;
	private _swipeTriggered: boolean;
	private _enabled: boolean = true;
	private _isMouseDown: boolean;
	private _isSwiping: boolean;
	private _preventSwiping: boolean;

	public onLeftSwipe = new signals.Signal();
	public onRightSwipe = new signals.Signal();
	public onSwipeEndSignal = new signals.Signal();
	public onSwipeStartSignal = new signals.Signal();

	/******************************************************************
	 * Constructor
	 *****************************************************************/

	constructor(private _gestureView: HTMLElement) {
		this.initListeners();
	}

	/******************************************************************
	 * Public Methodes
	 *****************************************************************/

	get enabled(): boolean {
		return this._enabled;
	}

	set enabled(value: boolean) {
		this._enabled = value;
	}

	get isSwiping(): boolean {
		return this._isSwiping;
	}

	/******************************************************************
	 * Private Methodes
	 *****************************************************************/

	private initListeners() {
		this._gestureView.addEventListener("touchstart", (e: TouchEvent) => this.onTouchStarted(e));
		this._gestureView.addEventListener("touchmove", (e: TouchEvent) => this.onTouchMove(e));
		this._gestureView.addEventListener("touchend", (e: TouchEvent) => this.onTouchEnd(e));
		this._gestureView.addEventListener("mousedown", (e: MouseEvent) => this.onMouseDown(e));
		this._gestureView.addEventListener("mousemove", (e: MouseEvent) => this.onMouseMove(e));
		this._gestureView.addEventListener("mouseup", (e: MouseEvent) => this.onMouseUp(e));
	}

	private checkForSwipe(dx: number) {
		if (this._swipeTriggered) return;
		if (Math.abs(dx) > 30) {
			this._swipeTriggered = true;
			if (dx > 0) {
				this.onRightSwipe.dispatch();
			} else {
				this.onLeftSwipe.dispatch();
			}
		}
	}

	private checkPreventScrolling(e: TouchEvent) {
		if (this._swipeTriggered) {
			e.preventDefault();
			return;
		}
		let dy = Math.abs(e.touches[0].clientY - this._touchStartY);
		if (dy > 10) {
			this._preventSwiping = true;
		}
	}

	/******************************************************************
	 * Events
	 *****************************************************************/

	private onTouchStarted(e: TouchEvent) {
		if (!this._enabled) return;
		this._preventSwiping = false;
		this._touchStartX = e.touches[0].clientX;
		this._touchStartY = e.touches[0].clientY;
	}

	private onTouchMove(e: TouchEvent) {
		if (!this._enabled || this._preventSwiping) return;
		this.checkForSwipe(e.touches[0].clientX - this._touchStartX);
		this.checkPreventScrolling(e);
		if (!this._isSwiping) {
			this._isSwiping = true;
			this.onSwipeStartSignal.dispatch();
		}
	}

	private onTouchEnd(e: TouchEvent) {
		if (!this._enabled) return;
		this._swipeTriggered = false;
		this._isSwiping = false;
		this.onSwipeEndSignal.dispatch();
	}

	private onMouseDown(e: MouseEvent) {
		if (!this._enabled) return;
		this._isMouseDown = true;
		this._mouseStartX = e.clientX;
	}

	private onMouseMove(e: MouseEvent) {
		if (!this._enabled) return;
		if (!this._isMouseDown) return;
		this.checkForSwipe(e.clientX - this._mouseStartX);
		if (!this._isSwiping) {
			this._isSwiping = true;
			this.onSwipeStartSignal.dispatch();
		}
	}

	private onMouseUp(e: MouseEvent) {
		if (!this._enabled) return;
		this._isMouseDown = false;
		this._swipeTriggered = false;
		this._isSwiping = false;
		this.onSwipeEndSignal.dispatch();
	}
}
