import * as signals from "signals";
import {TweenMax} from "gsap";
import {UIView} from "../../../../@jashson/core-ui/UIView";

/******************************************************************
 * BackgroundImage
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class BackgroundImage extends UIView {

	/******************************************************************
	 * Properties
	 *****************************************************************/

	static SIZE_COVER = "cover";
	static SIZE_CONTAIN = "contain";
	static POSITION_CENTER = "center";

	private _size: string = BackgroundImage.SIZE_CONTAIN;
	private _position: string = BackgroundImage.POSITION_CENTER;

	public onLoadCompleteSignal = new signals.Signal();

	/******************************************************************
	 * Constructor
	 *****************************************************************/

	constructor(private _imageURL: string = null) {
		super("background-image");
		if (this._imageURL) {
			this.imageURL = this._imageURL;
		}
	}

	/******************************************************************
	 * Public Methodes
	 *****************************************************************/

	set imageURL(url: string) {
		this._imageURL = url;
		let image = new Image();
		image.onload = () => {
			this.applyStyle({
				backgroundImage: "url('" + this._imageURL + "')"
			});
			TweenMax.delayedCall(0.1, () => {
				this.onLoadCompleteSignal.dispatch();
			})
		};
		image.src = this._imageURL;
	}

	set imageBlob(blob: Blob) {
        this.applyStyle({
            backgroundImage: "url('" + window.URL.createObjectURL(blob) + "')"
        });
    }

	set size(value: string) {
		this._size = value;
		this.updateStyles();
	}

	set position(value: string) {
		this._position = value;
		this.updateStyles();
	}

	public updateStyles() {
		this.applyStyle({
			backgroundRepeat: "no-repeat",
			backgroundPosition: this._position,
			backgroundSize: this._size
		});
	}

}
