import {PageSlideModel} from "./PageSlideModel";
import {PageSlideType} from "./PageSlideType";
import {PageType} from "./PageType";
import {ImageVO} from "../../../../vo/ImageVO";
import {AppConfig} from "../../../../config/AppConfig";

/******************************************************************
 * PageModel
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PageModel {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _slideModels: PageSlideModel[] = [];

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _pageJSON: any) {
        this.initSlideModels();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get title(): string {
        return this._pageJSON.title;
    }

    get type(): string {
        return this._pageJSON.type;
    }

    get deeplink(): string {
        return this._pageJSON.deeplink;
    }

    get slideModels(): PageSlideModel[] {
        return this._slideModels;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initSlideModels() {
        switch (this.type) {
            case PageType.CATEGORY:
                this._slideModels = this.createSlideModels(PageSlideType.CATEGORY_INFO);
                break;
            case PageType.ABOUT:
                this._slideModels = this.createSlideModels(PageSlideType.ABOUT_ME);
                break;
            case PageType.STUDIO:
                this._slideModels = this.createSlideModels(PageSlideType.STUDIO);
                break;
            case PageType.CONTACT:
                this._slideModels = this.createSlideModels(PageSlideType.CONTACT);
                break;
        }
    }

    private createSlideModels(fixedSlideType: string): PageSlideModel[] {
        let slideModels: PageSlideModel[] = [];
        slideModels.push(new PageSlideModel(fixedSlideType, this._pageJSON, this._pageJSON.deeplink));
        let photoStories = this.getPhotoStories();
        photoStories.forEach((photoStory: ImageVO[]) => {
            let slideType = PageSlideType.PHOTO_STORY;
            let imageVOs = photoStory;
            let slideDeeplink = this._pageJSON.deeplink + "/" + photoStory[0].name;
            let contentDeeplinks = this.getPhotoStoryContentDeeplinks(this._pageJSON.deeplink, imageVOs);
            slideModels.push(new PageSlideModel(slideType, imageVOs, slideDeeplink, contentDeeplinks));
        });
        if (photoStories && photoStories.length > 0) {
            slideModels.push(new PageSlideModel(PageSlideType.SHOW_MENU, null, this._pageJSON.deeplink + AppConfig.SHOW_MENU_SLIDE_DEEPLINK, null));
        }
        return slideModels;
    }

    private getPhotoStories(): ImageVO[][] {
        let photoStories: ImageVO[][] = [];
        if (!this._pageJSON.images) return photoStories;
        this._pageJSON.images.forEach((imageVO: ImageVO) => {
            let storyID = this.getPhotoStoryID(imageVO, photoStories);
            if (storyID == undefined) {
                let newPhotoStory: ImageVO[] = [];
                newPhotoStory.push(imageVO);
                photoStories.push(newPhotoStory);
            } else {
                photoStories[storyID].push(imageVO);
            }
        });
        return photoStories;
    }

    private getPhotoStoryID(targetImageVO: ImageVO, photoStories: ImageVO[][]): number {
        let storyID = undefined;
        if (!targetImageVO.storyID) return undefined;
        photoStories.forEach((photoStory: ImageVO[], i: number) => {
            photoStory.forEach((imageVO: ImageVO) => {
                if (imageVO.storyID == targetImageVO.storyID) {
                    storyID = i;
                }
            });
        });
        return storyID;
    }

    private getPhotoStoryContentDeeplinks(pageDeeplink: string, imageVOs: ImageVO[]): string[] {
        let deeplinks: string[] = [];
        imageVOs.forEach((imageVO: ImageVO) => {
            deeplinks.push(pageDeeplink + "/" + imageVO.name);
        });
        return deeplinks;
    }
}
