import {UIView} from "../../../../../../../../@jashson/core-ui/UIView";
import {ImageComponent} from "../../../../../basic/ImageComponent";
import {ImageVO} from "../../../../../../../vo/ImageVO";
import {Color} from "../../../../../../../style/Color";
import {TweenMax, Power4} from "gsap";
import * as signals from "signals";
import {SizeAndPosition} from "../../../../../../../style/SizeAndPosition";
import {ShadowStyle} from "../../../../../../../style/ShadowStyle";

/******************************************************************
 * Thumbnail
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Thumbnail extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private static readonly USE_FILTERS = false;

    private _image: ImageComponent;
    private _imageBox: UIView<HTMLDivElement>;
    private _selected: boolean;

    public onClickSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _imageVO: ImageVO) {
        super("thumbnail");
        this.initImageBox();
        this.initImage();
        this.initListener();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get imageVO(): ImageVO {
        return this._imageVO;
    }

    set selected(value: boolean) {
        this._selected = value;
        this.highlight(value);
    }

    public updateStyles() {
        this._imageBox.applyStyle({
            boxSizing: "border-box",
            cursor: "pointer",
            width: SizeAndPosition.THUMBNAIL_HEIGHT * this._imageVO.ratio,
            height: SizeAndPosition.THUMBNAIL_HEIGHT,
            borderRadius: SizeAndPosition.THUMBNAIL_BORDER_RADIUS,
            overflow: "hidden",
            boxShadow: "0px 14px 37px " + ShadowStyle.BLACK_SHADOW_RGBA,
            backgroundColor: ShadowStyle.BLACK_SHADOW_RGBA
        });
        this._image.applyStyle({
            position: "relative",
            filter: Thumbnail.USE_FILTERS ? (this._selected ? "" : "grayscale(90%)") : "none",
            width: "100%",
            height: "100%"
        })
    };

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initImageBox() {
        this._imageBox = new UIView("image-box");
        this.addChild(this._imageBox);
    }

    private initImage() {
        this._image = new ImageComponent();
        this._image.useSmallSizeProgressCircles = true;
        this._image.onImageLoadedSignal.addOnce(() => this.onImageLoaded());
        this._image.load(this._imageVO, true);
        this._imageBox.addChild(this._image);
    }

    private initListener() {
        this._imageBox.view.addEventListener("click", () => this.onImageClicked());
        this._imageBox.view.addEventListener("mouseover", () => this.onImageOver());
        this._imageBox.view.addEventListener("mouseout", () => this.onImageOut());
    }

    private highlight(value: boolean) {
        TweenMax.to(this._image.view, 0.4, {
            filter: Thumbnail.USE_FILTERS ? (value ? "grayscale(0%)" : "grayscale(90%)") : "none",
            scale: value ? 1.1 : 1,
            ease: Power4.easeOut
        });
        TweenMax.to(this._imageBox.view, 0.4, {
            scale: value ? 1.1 : 1,
            border: (this._selected ? 2 : 0) + "px solid " + Color.RED,
            ease: Power4.easeOut
        });
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onImageClicked() {
        this.onClickSignal.dispatch();
    }

    private onImageOver() {
        if (this._selected) return;
        this.highlight(true);
    }

    private onImageOut() {
        if (this._selected) return;
        this.highlight(false);
    }

    private onImageLoaded() {
        if (this._selected) {
            this.highlight(true);
        }
    }
}
