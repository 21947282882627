/******************************************************************
 * FontStyle
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/
import {SizeAndPosition} from "./SizeAndPosition";

export class FontStyle {

    /******************************************************************
     * FONT FAMILIES
     *****************************************************************/

    static FONT_FAMILY = "Maven Pro";

    /******************************************************************
     * FONT WEIGHTS
     *****************************************************************/

    static FONT_WEIGHT_REGULAR = 400;
    static FONT_WEIGHT_MEDIUM = 500;
    static FONT_WEIGHT_BOLD = 700;

    /******************************************************************
     * FONT SIZES
     *****************************************************************/

    static get FONT_SIZE_MENU_ITEM(): number {
        let fontSize = SizeAndPosition.STAGE_WIDTH * 0.05;
        if (fontSize > 20) fontSize = 20;
        if (fontSize < 18) fontSize = 18;
        return fontSize;
    };

    static get FONT_SIZE_PASSWORD(): number {
        let fontSize = SizeAndPosition.STAGE_WIDTH * 0.025;
        if (fontSize > 30) fontSize = 30;
        if (fontSize < 14) fontSize = 14;
        return fontSize;
    };

    static get FONT_SIZE_CAPTION(): number {
        return FontStyle.FONT_SIZE_HEADER_TITLE;
    };

    static get FONT_SIZE_LABELBUTTON(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 8 : 14;
    };

    static get FONT_SIZE_HEADER_TITLE(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 14 : 16;
    };

    static get FONT_SIZE_CONTENT_COPY(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 14 : 22;
    };

    static get FONT_SIZE_META_COPY(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 14 : 16;
    };

    static get FONT_SIZE_META_H1(): number {
        return FontStyle.FONT_SIZE_PASSWORD;
    };

    static get FONT_SIZE_META_H2(): number {
        return SizeAndPosition.IS_SMALL_SCREEN ? 14 : 16;
    };
}
