/******************************************************************
 * PageType
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PageType {

    static CATEGORY = "category";
    static ABOUT = "about";
    static STUDIO = "studio";
    static CONTACT = "contact";

}
