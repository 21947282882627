import {UIView} from "../../../../@jashson/core-ui/UIView";
import {ILinkVO} from "../../../vo/ILinkVO";
import {SVGView} from "../../../../@jashson/core-svg/SVGView";
import {URLUtils} from "../../../../@jashson/core-utils/URLUtils";
import {TweenMax, Power4} from "gsap";
import {Color} from "../../../style/Color";
import {ShadowStyle} from "../../../style/ShadowStyle";
import {SizeAndPosition} from "../../../style/SizeAndPosition";

/******************************************************************
 * LinkButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class LinkButton extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    static readonly AVAILABLE_ICONS = {
        "blog": require("ASSETS/images/button-blog.svg"),
        "facebook": require("ASSETS/images/button-facebook.svg"),
        "dasauge": require("ASSETS/images/button-dasauge.svg"),
        "instagram": require("ASSETS/images/button-instagram.svg"),
        "linkedin": require("ASSETS/images/button-linkedin.svg"),
        "xing": require("ASSETS/images/button-xing.svg")
    };
    private _iconSVG: SVGView;
    private _iconSVGElement: SVGElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _linkVO: ILinkVO) {
        super("link-button");
        this.initButtonIcon();
        this.initListener();
        this.interactive = true;
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            cursor: "pointer"
        });
        if (!this._iconSVG) return;
        this._iconSVG.applyStyle({
            fontSize: 0,
            overflow: "hidden",
            borderRadius: 200,
            boxShadow: ShadowStyle.SMALL_SHADOW,
            scale: 1
        });
        this._iconSVG.width = SizeAndPosition.ICON_BUTTON_SIZE;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initButtonIcon() {
        let iconURL = this.getIconURL();
        if (!iconURL) return;
        this._iconSVG = new SVGView(iconURL);
        this._iconSVG.clearSizeDefinition();
        this._iconSVGElement = this._iconSVG.getElementByID("icon");
        this.addChild(this._iconSVG);
    }

    private initListener() {
        if (!this._iconSVG) return;
        this._iconSVG.view.addEventListener("click", () => this.onClicked());
        this._iconSVG.view.addEventListener("mouseover", () => this.onMouseOver());
        this._iconSVG.view.addEventListener("mouseout", () => this.onMouseOut());
    }

    private getIconURL(): string {
        let url = undefined;
        Object.keys(LinkButton.AVAILABLE_ICONS).forEach((iconKey: string) => {
            if (this._linkVO.url.toLowerCase().indexOf(iconKey) != -1) {
                url = LinkButton.AVAILABLE_ICONS[iconKey];
            }
        });
        return url;
    }

    private highlight(value: boolean) {
        TweenMax.to(this._iconSVGElement, 0.5, {
            fill: value ? Color.WHITE : Color.GREY,
            ease: Power4.easeOut
        });
        TweenMax.to(this._iconSVG.view, 0.5, {
            boxShadow: value ? ShadowStyle.STANDARD_SHADOW : ShadowStyle.SMALL_SHADOW,
            ease: Power4.easeOut,
            scale: value ? 1.1 : 1
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onClicked() {
        URLUtils.openLink(this._linkVO.url);
        this.highlight(false);
    }

    private onMouseOver() {
        this.highlight(true);
    }

    private onMouseOut() {
        this.highlight(false);
    }
}
