import {SVGView} from "../../../../../../@jashson/core-svg/SVGView";
import {Color} from "../../../../../style/Color";
import {Power1, Power4, TweenMax} from "gsap";
import {UIView} from "../../../../../../@jashson/core-ui/UIView";
import * as signals from "signals";
import {AppConfig} from "../../../../../config/AppConfig";
import {SizeAndPosition} from "../../../../../style/SizeAndPosition";

/******************************************************************
 * MenuLogo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class MenuLogo extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _nameLetters: SVGElement[];
    private _professsionLetters: SVGElement[];
    private _circles: SVGElement[];
    private _circleBox: SVGElement;
    private _circleCenterHelper: SVGElement;
    private _viewBox: SVGElement;
    private _svg: SVGView;
    private _svgOffsetX: number = 0;
    private _svgOffsetY: number = 0;
    private _currentRotation = 0;
    private _isShowingAsMenu: boolean = false;
    private _isShowingAtTop: boolean = false;
    private _isIntroCompleted: boolean = false;
    private _useVerticalMenu: boolean = false;

    public onIntroCompleteSignal = new signals.Signal();


    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("logo-svg");
        this.initSVG();
        this.initSVGElements();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    set useVerticalMenu(value: boolean) {
        this._useVerticalMenu = value;
    }

    get width(): number {
        return this.calcSVGWidth();
    }

    get circleCenterClientRect(): ClientRect {
        return this._circleCenterHelper.getBoundingClientRect();
    }

    set svgOffsetX(value: number) {
        this._svgOffsetX = value;
        this.tweenLogoSVGOffsets(0);
    }

    set svgOffsetY(value: number) {
        this._svgOffsetY = value;
        this.tweenLogoSVGOffsets(0);
    }

    public playIntro() {
        TweenMax.delayedCall(3, () => {
            this._isIntroCompleted = true;
            this.onIntroCompleteSignal.dispatch();
        });
        this._nameLetters.forEach((letter: SVGElement, i: number) => {
            TweenMax.set(letter, {
                stroke: Color.GREY,
                strokeWidth: 0.3,
                fill: "rgba(255, 255, 255, 0)",
                drawSVG: "0%"
            });
            TweenMax.to(letter, 0.7, {
                delay: i * 0.04 + 0.2,
                drawSVG: "100%",
                ease: Power1.easeInOut,
            });
            TweenMax.to(letter, 0.5, {
                delay: Math.random() + 0.6,
                strokeWidth: 0,
                fill: "rgba(255, 255, 255, 1)",
                ease: Power1.easeIn,
            })
        });
        this._professsionLetters.forEach((letter: SVGElement, i: number) => {
            TweenMax.set(letter, {
                alpha: 0
            });
            TweenMax.to(letter, 0.7, {
                delay: i * 0.015 + 1.3,
                alpha: 1,
                ease: Power1.easeInOut,
            });
        });
        this._circles.forEach((circle: SVGElement, i: number) => {
            TweenMax.set(circle, {
                stroke: Color.GREY,
                strokeWidth: 0.5,
                fill: "rgba(255, 255, 255, 0)",
                drawSVG: "0%",
            });
            TweenMax.to(circle, 1, {
                delay: i * 0.3,
                drawSVG: "100%",
                ease: Power1.easeInOut,
            });
            let delay = i * 0.1 + 1.5;
            TweenMax.to(circle, 0.4, {
                delay: delay,
                strokeWidth: 0,
                ease: Power1.easeOut,
            });
            TweenMax.to(circle, 0.2, {
                delay: delay,
                fill: "rgba(255, 255, 255, 0.5)",
                ease: Power1.easeIn,
            })
        });
    }

    public showAsFullLogo() {
        this._isShowingAsMenu = false;
        this._isShowingAtTop = false;
        this.tweenLogoSVGOffsets(AppConfig.CHANGE_PAGE_DURATION);
        this._nameLetters.forEach((letter: SVGElement, i: number) => {
            TweenMax.to(letter, AppConfig.CHANGE_PAGE_DURATION * 0.7, {
                delay: i * AppConfig.CHANGE_PAGE_DURATION * 0.007 + AppConfig.CHANGE_PAGE_DURATION * 0.07,
                x: 0,
                alpha: 1,
                ease: Power4.easeInOut
            })
        });
        if (this._isIntroCompleted) {
            this._professsionLetters.forEach((letter: SVGElement, i: number) => {
                TweenMax.to(letter, AppConfig.CHANGE_PAGE_DURATION * 0.7, {
                    delay: i * AppConfig.CHANGE_PAGE_DURATION * 0.007,
                    x: 0,
                    alpha: 1,
                    ease: Power4.easeInOut
                })
            });
        }
        TweenMax.to(this._circleBox, AppConfig.CHANGE_PAGE_DURATION, {
            rotation: this.calcCircleRotation(),
            scale: 1,
            ease: Power4.easeInOut
        });
        this._circles.forEach((circle: SVGElement) => {
            TweenMax.to(circle, AppConfig.CHANGE_PAGE_DURATION, {
                bezier: [
                    {
                        x: Math.random() * 60 - 30,
                        y: Math.random() * 60 - 30
                    },
                    {
                        x: 0,
                        y: 0
                    }
                ],
                ease: Power4.easeInOut
            })
        })
    }

    public showAsMenuLogo(showingAtTop: boolean = false) {
        let easing = Power4.easeInOut;
        this._isShowingAsMenu = true;
        this._isShowingAtTop = showingAtTop;
        this.tweenLogoSVGOffsets(AppConfig.CHANGE_PAGE_DURATION);
        this._nameLetters.forEach((letter: SVGElement, i: number) => {
            let offsetX = this.view.getBoundingClientRect().left - letter.getBoundingClientRect().left + 75;
            TweenMax.to(letter, AppConfig.CHANGE_PAGE_DURATION * 0.7, {
                delay: i * AppConfig.CHANGE_PAGE_DURATION * 0.007 + AppConfig.CHANGE_PAGE_DURATION * 0.07,
                x: offsetX,
                alpha: 0,
                ease: easing
            })
        });
        this._professsionLetters.forEach((letter: SVGElement, i: number) => {
            let offsetX = this.view.getBoundingClientRect().left - letter.getBoundingClientRect().left + 75;
            TweenMax.killTweensOf(letter);
            TweenMax.to(letter, AppConfig.CHANGE_PAGE_DURATION * 0.7, {
                delay: i * AppConfig.CHANGE_PAGE_DURATION * 0.007,
                x: offsetX,
                alpha: 0,
                ease: easing
            })
        });
        TweenMax.to(this._circleBox, AppConfig.CHANGE_PAGE_DURATION, {
            rotation: this.calcCircleRotation(),
            scale: 0.5,
            ease: easing
        });
        this._circles.forEach((circle: SVGElement) => {
            TweenMax.to(circle, AppConfig.CHANGE_PAGE_DURATION, {
                bezier: [
                    {
                        x: Math.random() * 60 - 30,
                        y: Math.random() * 60 - 30
                    },
                    {
                        x: 0,
                        y: 0
                    }
                ],
                ease: easing
            })
        })
    }

    public updateStyles() {
        TweenMax.set(this._circleBox, {
            transformOrigin: "56% 50%"
        });
        TweenMax.set(this._viewBox, {
            autoAlpha: 0
        });
        TweenMax.set(this._circleCenterHelper, {
            autoAlpha: 0
        });
        this._svg.width = this.calcSVGWidth();
        this.tweenLogoSVGOffsets(0);
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initSVG() {
        this._svg = new SVGView(require("ASSETS/images/grottker-logo-white.svg"));
        this._svg.clearSizeDefinition();
        this.addChild(this._svg);
    }

    private initSVGElements() {
        this._nameLetters = Array.from(this._svg.getElementByID("name-letters").childNodes) as SVGElement[];
        this._professsionLetters = Array.from(this._svg.getElementByID("profession-letters").childNodes) as SVGElement[];
        this._circleCenterHelper = this._svg.getElementByID("center-point") as SVGElement;
        this._circleBox = this._svg.getElementByID("circles-logo");
        this._circles = Array.from(this._circleBox.childNodes) as SVGElement[];
        this._viewBox = this._svg.getElementByID("viewbox") as SVGElement;
    }

    private tweenLogoSVGOffsets(duration: number) {
        TweenMax.killTweensOf(this._svg.view);
        let x = 0;
        if (this._useVerticalMenu) {
            if (this._isShowingAsMenu || this._isShowingAtTop) {
                x = this._svgOffsetX;
            }
        } else {
            if (this._isShowingAsMenu && !this._isShowingAtTop) {
                x = this._svgOffsetX;
            }
        }
        TweenMax.to(this._svg.view, duration, {
            x: x,
            y: this._useVerticalMenu && this._isShowingAsMenu && !this._isShowingAtTop ? (this._svgOffsetY) : 0,
            ease: Power4.easeInOut
        });
    }

    private calcCircleRotation() {
        this._currentRotation += 240;
        return this._currentRotation;
    }

    private calcSVGWidth() {
        let svgWidth = this._svg.defaultWidth;
        if (svgWidth > SizeAndPosition.STAGE_WIDTH * 0.9) {
            svgWidth = SizeAndPosition.STAGE_WIDTH * 0.9;
        }
        return svgWidth;
    }

    /******************************************************************
     * Events
     *****************************************************************/

}
