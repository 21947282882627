import {UIView} from "../../../../../@jashson/core-ui/UIView";
import {ShadowStyle} from "../../../../style/ShadowStyle";
import {SizeAndPosition} from "../../../../style/SizeAndPosition";
import * as signals from "signals";
import {Power4, TweenMax} from "gsap";
import {AppConfig} from "../../../../config/AppConfig";
import {IconButton} from "../../basic/IconButton";
import {RequestUtils} from "../../../../../@jashson/core-utils/RequestUtils";
import {Color} from "../../../../style/Color";
import {FontStyle} from "../../../../style/FontStyle";
import {ElementStyle} from "../../../../style/ElementStyle";

/******************************************************************
 * MetaLayer
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class MetaLayer extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _layerFrame: UIView<HTMLDivElement>;
    private _scrollBox: UIView<HTMLDivElement>;
    private _titleField: UIView<HTMLDivElement>;
    private _descField: UIView<HTMLDivElement>;
    private _closeButton: IconButton;
    private _isShowing: boolean = true;
    private _contentLoaded: boolean = false;

    public onShowSignal = new signals.Signal();
    public onHideSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("meta-layer");
        this.initScrollBox();
        this.initLayerFrame();
        this.initTitleField();
        this.initDescField();
        this.initCloseButton();
        this.hide(true);
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/


    public show() {
        if (this._isShowing) return;
        this._isShowing = true;
        this.interactive = true;
        if (!this._contentLoaded) {
            this._contentLoaded = true;
            this.loadContent();
        }
        TweenMax.to(this.view, AppConfig.META_LAYER_DURATION, {
            y: "0%",
            autoAlpha: 1,
            ease: Power4.easeInOut
        });
        this.onShowSignal.dispatch();
    }

    public hide(instantly: boolean = false) {
        if (!this._isShowing) return;
        this._isShowing = false;
        this.interactive = false;
        TweenMax.to(this.view, instantly ? 0 : AppConfig.META_LAYER_DURATION, {
            y: "100%",
            autoAlpha: 0,
            ease: Power4.easeInOut
        });
        if (!instantly) {
            this.onHideSignal.dispatch();
        }
    }

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            top: 0,
            left: 0,
            width: SizeAndPosition.STAGE_WIDTH,
            height: SizeAndPosition.STAGE_HEIGHT,
            overflow: "hidden",
            zIndex: 1000
        });
        this._scrollBox.applyStyle({
            position: "absolute",
            top: 0,
            left: 0,
            width: SizeAndPosition.STAGE_WIDTH,
            height: SizeAndPosition.STAGE_HEIGHT,
            overflowX: "hidden",
            overflowY: "scroll",
            webkitOverflowScrolling: "touch",
        });
        this._layerFrame.applyStyle({
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: SizeAndPosition.IS_SMALL_SCREEN ? SizeAndPosition.HEADER_HEIGHT : SizeAndPosition.APP_UI_MARGIN * 3,
            marginBottom: SizeAndPosition.APP_UI_MARGIN * 3,
            width: SizeAndPosition.STAGE_WIDTH * 0.9,
            maxWidth: SizeAndPosition.MAX_TEXT_WIDTH + "px",
            backgroundColor: ShadowStyle.DARK_SHADOW_RGBA,
            borderRadius: SizeAndPosition.IS_SMALL_SCREEN ? 10 : 20,
            boxShadow: ShadowStyle.STANDARD_SHADOW,
            padding: SizeAndPosition.IS_SMALL_SCREEN ? 20 : 90,
            boxSizing: "border-box",
        });
        this._closeButton.applyStyle({
            position: "absolute",
            right: SizeAndPosition.APP_UI_MARGIN,
            top: SizeAndPosition.APP_UI_MARGIN * (SizeAndPosition.IS_SMALL_SCREEN ? 1 : 3)
        });
        this._titleField.applyStyle({
            color: Color.GREY,
            textAlign: "center",
            fontSize: FontStyle.FONT_SIZE_META_H1,
            marginBottom: FontStyle.FONT_SIZE_META_H1 * 3,
            textTransform: "uppercase"
        });
        this._descField.applyStyle({
            color: Color.GREY,
            fontSize: FontStyle.FONT_SIZE_META_COPY,
            lineHeight: FontStyle.FONT_SIZE_META_COPY * 1.4 + "px"
        });
        TweenMax.set(this._descField.view.getElementsByTagName("h1"), {
            marginTop: FontStyle.FONT_SIZE_META_H2 * 3,
            marginBottom: FontStyle.FONT_SIZE_META_H2,
            fontWeight: FontStyle.FONT_WEIGHT_BOLD,
            fontSize: FontStyle.FONT_SIZE_META_H2
        });
        ElementStyle.applyMetaCopyAnchorStyle(Array.from(this._descField.view.getElementsByTagName("a")));
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initScrollBox() {
        this._scrollBox = new UIView("scroll-box");
        this.addChild(this._scrollBox);
    }

    private initCloseButton() {
        this._closeButton = new IconButton(require("ASSETS/images/close-button.svg"));
        this._closeButton.onClickSignal.add(() => this.onCloseClicked());
        this.addChild(this._closeButton);
    }

    private initLayerFrame() {
        this._layerFrame = new UIView("layer-frame");
        this._scrollBox.addChild(this._layerFrame);
    }

    private initTitleField() {
        this._titleField = new UIView("title");
        this._layerFrame.addChild(this._titleField);
    }

    private initDescField() {
        this._descField = new UIView("desc");
        this._layerFrame.addChild(this._descField);
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onCloseClicked() {
        this.hide();
    }

    private loadContent() {
        RequestUtils.getURL(AppConfig.API_URL + "/imprintdata", (result) => {
            let jsonVO = JSON.parse(result);
            this._titleField.view.innerHTML = jsonVO.title;
            this._descField.view.innerHTML = jsonVO.text;
            this.updateStyles();
        });
    }
}
