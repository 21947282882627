import {UIView} from "../../../../../../../../@jashson/core-ui/UIView";
import {ImageVO} from "../../../../../../../vo/ImageVO";
import {ImageComponent} from "../../../../../basic/ImageComponent";
import {Power4, TweenMax} from "gsap";
import * as signals from "signals";
import {GSAPUtils} from "../../../../../../../../@jashson/core-utils/GSAPUtils";
import {ShadowStyle} from "../../../../../../../style/ShadowStyle";
import {SizeAndPosition} from "../../../../../../../style/SizeAndPosition";

/******************************************************************
 * MainImage
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class MainImage extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _imageBox: UIView<HTMLDivElement>;
    private _image: ImageComponent;
    private _imageShadowScale: number = 1;
    private _imagePercentScale: number = 1;
    private _isLeft: boolean;
    private _isTweeningImageSize: boolean;
    private _currentImageVO: ImageVO;

    public onMainImageResizeSignal = new signals.Signal();
    public onImageLoadedSignal = new signals.Signal();
    public onClickSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("main-image");
        this.initImageBox();
        this.initImage();
        this.initInteraction();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get currentImageVO(): ImageVO {
        return this._currentImageVO;
    }

    set isLeft(value: boolean) {
        this._isLeft = value;
    }

    get imageClientX(): number {
        return this._image.view.getBoundingClientRect().left - GSAPUtils.getTransformX(this._imageBox.view);
    }

    get imageClientY(): number {
        return this._image.view.getBoundingClientRect().top;
    }

    get imageHeight(): number {
        return this._image.view.getBoundingClientRect().height;
    }

    get imageWidth(): number {
        return this._image.view.getBoundingClientRect().width;
    }

    set highlight(value: boolean) {
        if (this._isTweeningImageSize) return;
        if (!this.interactive) value = false;
        TweenMax.to(this._imageBox.view, 0.3, {
            x: value ? (this._isLeft ? 20 : -20) : 0,
            ease: Power4.easeOut
        })
    };

    public tweenToImageSize(imageVO: ImageVO, scale: number = 1) {
        this._isTweeningImageSize = true;
        this._imagePercentScale = scale;
        TweenMax.killTweensOf(this._imageBox.view);
        TweenMax.killTweensOf(this);
        TweenMax.to(this._imageBox.view, 1, {
            width: this.calcWidthPercent(imageVO),
            height: this.calcHeightPercent(imageVO),
            ease: Power4.easeInOut,
            x: 0,
            onUpdate: () => {
                this.onMainImageResizeSignal.dispatch();
            },
            onComplete: () => {
                this._isTweeningImageSize = false;
            }
        });
        TweenMax.to(this, 1, {
            imageScale: scale,
            ease: Power4.easeInOut,
        });
    }

    public showImage(imageVO: ImageVO, scale: number = 1) {
        this.tweenToImageSize(imageVO, scale);
        this.loadImage(imageVO);
    }

    public loadImage(imageVO: ImageVO) {
        this._currentImageVO = imageVO;
        if (this._image.imageVO == imageVO) return;
        this._image.load(imageVO);
    }

    public updateStyles() {
        this._image.applyStyle({
            position: "absolute",
            width: "100%",
            height: "100%",
            cursor: "pointer"
        });
        this._imageBox.applyStyle({
            position: "absolute",
            top: "50%",
            left: "50%",
            x: "-50%",
            y: "-50%",
            width: this.calcWidthPercent(),
            height: this.calcHeightPercent(),
            borderRadius: 8,
            backgroundColor: ShadowStyle.BLACK_SHADOW_RGBA,
            overflow: "hidden"
        });
        this.updateImageShadow();
        this.onMainImageResizeSignal.dispatch();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initImageBox() {
        this._imageBox = new UIView("image-box");
        this.addChild(this._imageBox);
    }

    private initImage() {
        this._image = new ImageComponent();
        this._image.onImageLoadedSignal.add(() => this.onImageLoadedSignal.dispatch());
        this._imageBox.addChild(this._image);
    }

    private initInteraction() {
        this._image.view.addEventListener("click", () => this.onImageClicked());
        this._image.view.addEventListener("mouseover", () => this.onImageOver());
        this._image.view.addEventListener("mouseout", () => this.onImageOut());
    }

    private calcWidthPercent(imageVO: ImageVO = null): string {
        if (!imageVO && this._image && this._image.imageVO) {
            imageVO = this._image.imageVO;
        }
        if (!imageVO) return "10%";
        if (imageVO.ratio >= this.calcViewRatio()) {
            return 100 * this._imagePercentScale + "%";
        } else {
            return this.view.getBoundingClientRect().height * imageVO.ratio * this._imagePercentScale + "px"
        }
    }

    private calcHeightPercent(imageVO: ImageVO = null): string {
        if (!imageVO && this._image && this._image.imageVO) {
            imageVO = this._image.imageVO;
        }
        if (!imageVO) return "20%";
        if (imageVO.ratio <= this.calcViewRatio()) {
            return 100 * this._imagePercentScale + "%";
        } else {
            return 1 / imageVO.ratio * this.view.getBoundingClientRect().width * this._imagePercentScale + "px"
        }
    }

    private get imageScale(): number {
        return this._imageShadowScale;
    }

    private set imageScale(value: number) {
        this._imageShadowScale = value;
        this.updateImageShadow();
    }

    private calcBoxShadow(): string {
        let blurRadius = 150 * this._imageShadowScale * this._imageShadowScale * this._imageShadowScale;
        let shadowScale = this.view.offsetWidth / SizeAndPosition.MAX_TEXT_WIDTH;
        if (shadowScale > 1) shadowScale = 1;
        return "0px " + blurRadius * 0.3 * shadowScale + "px " + blurRadius * shadowScale + "px " + ShadowStyle.BLACK_SHADOW_RGBA;
    }

    private calcViewRatio(): number {
        return this.view.getBoundingClientRect().width / this.view.getBoundingClientRect().height;
    }

    private updateImageShadow() {
        if (SizeAndPosition.IS_SMALL_SCREEN) return;
        this._imageBox.applyStyle({
            boxShadow: this.calcBoxShadow()
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onImageClicked() {
        this.onClickSignal.dispatch();
    }

    private onImageOver() {
        this.highlight = true;
    }

    private onImageOut() {
        this.highlight = false;
    }
}
