import {Color} from "./Color";
import {SizeAndPosition} from "./SizeAndPosition";

/******************************************************************
 * ShadowStyle
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ShadowStyle {

    static DARK_SHADOW_RGBA = "rgba(51, 51, 51, 0.9)";
    static BLACK_SHADOW_RGBA = "rgba(0, 0, 0, 0.3)";
    static LIGHT_BLACK_SHADOW_RGBA = "rgba(0, 0, 0, 0.1)";

   static get STANDARD_SHADOW(): string {
       if (SizeAndPosition.IS_SMALL_SCREEN) {
           return "0px 9px 20px " + ShadowStyle.BLACK_SHADOW_RGBA;
       }
       return "0px 17px 32px " + ShadowStyle.BLACK_SHADOW_RGBA;
   }

   static get SMALL_SHADOW(): string {
       if (SizeAndPosition.IS_SMALL_SCREEN) {
           return "0px 4px 6px " + ShadowStyle.LIGHT_BLACK_SHADOW_RGBA;
       }
       return "0px 7px 12px " + ShadowStyle.LIGHT_BLACK_SHADOW_RGBA;
   }

}
