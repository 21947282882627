/******************************************************************
 * PageSlideType
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PageSlideType {
    static CATEGORY_INFO = "category-info";
    static ABOUT_ME = "about";
    static STUDIO = "studio";
    static CONTACT = "contact";
    static PHOTO_STORY = "photo-story";
    static SHOW_MENU = "show-menu";
}
