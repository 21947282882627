import {UIView} from "../../../../../@jashson/core-ui/UIView";
import {WheelController} from "../../../../../@jashson/core-ui/controller/WheelController";
import {SizeAndPosition} from "../../../../style/SizeAndPosition";
import * as signals from "signals";
import {Power4, TweenMax} from "gsap";
import {SwipeGestures} from "../../../../../@jashson/core-ui/controller/SwipeGestures";
import {CoreView} from "../../../../core/CoreView";
import {RoundedArrowButton} from "../../interface/RoundedArrowButton";
import {AppConfig} from "../../../../config/AppConfig";

/******************************************************************
 * ArrowNavigation
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ArrowNavigation extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _wheelController: WheelController;
    private _downArrow: RoundedArrowButton;
    private _upArrow: RoundedArrowButton;
    private _leftArrow: RoundedArrowButton;
    private _rightArrow: RoundedArrowButton;
    private _gestureController: SwipeGestures;
    private _buttonBox: UIView<HTMLDivElement>;
    private _isBlocking: boolean = false;

    static PREVENT_NAVIGATION = false;

    public onDownSignal = new signals.Signal();
    public onUpSignal = new signals.Signal();
    public onLeftSignal = new signals.Signal();
    public onRightSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _coreView: CoreView) {
        super("arrow-navigation");
        this.initWheelController();
        this.initKeyController();
        this.initGestureController();
        this.initArrows();
        this.hide(true);
        this.interactive = false;
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get rightArrow(): RoundedArrowButton {
        return this._rightArrow;
    }

    get leftArrow(): RoundedArrowButton {
        return this._leftArrow;
    }

    get upArrow(): RoundedArrowButton {
        return this._upArrow;
    }

    get downArrow(): RoundedArrowButton {
        return this._downArrow;
    }

    public playIntro() {
        TweenMax.set(this.view, {
            autoAlpha: 0,
            y: "200%",
        });
        TweenMax.to(this.view, 1, {
            delay: AppConfig.SHOW_UI_DELAY,
            y: "0%",
            autoAlpha: this.calcAlpha(),
            ease: Power4.easeOut,
            onComplete: () => {
                this.interactive = true;
            }
        })
    }

    public show() {
        this.interactive = true;
        TweenMax.to(this.view, AppConfig.META_LAYER_DURATION, {
            y: "0%",
            autoAlpha: this.calcAlpha(),
            ease: Power4.easeOut
        })
    }

    public hide(instantly: boolean = false) {
        this.interactive = false;
        TweenMax.to(this.view, instantly ? 0 : AppConfig.META_LAYER_DURATION, {
            y: "200%",
            autoAlpha: 0,
            ease: Power4.easeIn
        })
    }

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            width: 76,
            height: 55,
            bottom: SizeAndPosition.APP_UI_MARGIN,
            x: "-50%",
            left: "50%",
            scale: SizeAndPosition.IS_SMALL_SCREEN ? 0.6 : 1,
            transformOrigin: "50% 100%",
            autoAlpha: this.calcAlpha()
        });
        this._downArrow.applyStyle({
            position: "absolute",
            rotation: -90,
            left: 3,
            top: -2,
            x: "100%",
            y: "100%"
        });
        this._upArrow.applyStyle({
            position: "absolute",
            rotation: 90,
            top: 0,
            left: 0,
            x: "100%"
        });
        this._leftArrow.applyStyle({
            position: "absolute",
            rotation: 0,
            left: 0,
            top: 0,
            x: "0%",
            y: "100%"
        });
        this._rightArrow.applyStyle({
            position: "absolute",
            rotation: 180,
            left: 3,
            top: -3,
            x: "200%",
            y: "100%"
        });
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initWheelController() {
        this._wheelController = new WheelController(document.body);
        this._wheelController.onUpSignal.add(() => this.onUp());
        this._wheelController.onDownSignal.add(() => this.onDown());
    }

    private initKeyController() {
        window.addEventListener("keydown", (e: KeyboardEvent) => this.onKeyDown(e));
    }

    private initGestureController() {
        this._gestureController = new SwipeGestures(this._coreView.view);
        this._gestureController.onLeftSwipe.add(() => this.onRight());
        this._gestureController.onRightSwipe.add(() => this.onLeft());
    }

    private initArrows() {
        this._buttonBox = new UIView("button-box");
        this._downArrow = new RoundedArrowButton();
        this._downArrow.onClickSignal.add(() => this.onDown());
        this._upArrow = new RoundedArrowButton();
        this._upArrow.onClickSignal.add(() => this.onUp());
        this._leftArrow = new RoundedArrowButton();
        this._leftArrow.onClickSignal.add(() => this.onLeft());
        this._rightArrow = new RoundedArrowButton();
        this._rightArrow.onClickSignal.add(() => this.onRight());
        this.addChild(this._buttonBox);
        this._buttonBox.addChild(this._downArrow);
        this._buttonBox.addChild(this._upArrow);
        this._buttonBox.addChild(this._leftArrow);
        this._buttonBox.addChild(this._rightArrow);
    }

    private startBlocking() {
        this._isBlocking = true;
        TweenMax.delayedCall(0.1, () => {
            this._isBlocking = false;
        })
    }

    private calcAlpha() {
        return SizeAndPosition.USE_LANDSCAPE_MODE ? 0 : 1
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onUp() {
        if (!this.interactive || !this._upArrow.enabled || this._isBlocking || ArrowNavigation.PREVENT_NAVIGATION) return;
        this.startBlocking();
        this._upArrow.highlight(true, true);
        this.onUpSignal.dispatch();
    }

    private onDown() {
        if (!this.interactive || !this._downArrow.enabled || this._isBlocking || ArrowNavigation.PREVENT_NAVIGATION) return;
        this.startBlocking();
        this._downArrow.highlight(true, true);
        this.onDownSignal.dispatch();
    }

    private onLeft() {
        if (!this.interactive || !this._leftArrow.enabled || this._isBlocking || ArrowNavigation.PREVENT_NAVIGATION) return;
        this.startBlocking();
        this._leftArrow.highlight(true, true);
        this.onLeftSignal.dispatch();
    }

    private onRight() {
        if (!this.interactive || !this._rightArrow.enabled || this._isBlocking || ArrowNavigation.PREVENT_NAVIGATION) return;
        this.startBlocking();
        this._rightArrow.highlight(true, true);
        this.onRightSignal.dispatch();
    }

    private onKeyDown(e: KeyboardEvent) {
        switch (e.key) {
            case "ArrowUp":
            case "Up":
                this.onUp();
                break;
            case "ArrowRight":
            case "Right":
                this.onRight();
                break;
            case "ArrowDown":
            case "Down":
                this.onDown();
                break;
            case "ArrowLeft":
            case "Left":
                this.onLeft();
                break;
        }
    }
}
