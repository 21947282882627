import {UIView} from "../../../../@jashson/core-ui/UIView";
import {SVGView} from "../../../../@jashson/core-svg/SVGView";
import {TweenMax} from "gsap";
import {Color} from "../../../style/Color";
import * as signals from "signals";
import {SizeAndPosition} from "../../../style/SizeAndPosition";

/******************************************************************
 * RoundedArrowButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class RoundedArrowButton extends UIView {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _arrowSVG: SVGView;
    private _arrowElement: SVGElement;
    private _hitzone: SVGElement;
    private _enabled: boolean = true;
    private _circle: SVGElement;

    public onClickSignal = new signals.Signal();
    public onOverSignal = new signals.Signal();
    public onOutSignal = new signals.Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("litte-arrow-button");
        this.initArrowSVG();
        this.initElements();
        this.initListeners();
        this.highlight(false);
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public show() {
        this.interactive = !SizeAndPosition.IS_SMALL_SCREEN;
        TweenMax.to(this.view, 1, {
            autoAlpha: 1
        })
    }

    public hide(instantly: boolean = false) {
        this.interactive = false;
        TweenMax.to(this.view, instantly ? 0 : 0.1, {
            autoAlpha: 0
        })
    }

    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(value: boolean) {
        this._enabled = value;
        this.interactive = !SizeAndPosition.IS_SMALL_SCREEN;
        TweenMax.to(this._arrowSVG.view, 0.3, {
            autoAlpha: value ? 1 : 0.2
        })
    }

    public highlight(value, autoRemove: boolean = false) {
        TweenMax.killTweensOf(this._arrowElement);
        TweenMax.killTweensOf(this._circle);
        TweenMax.to(this._circle, value ? 0.1 : 0.3, {
            alpha: value ? 1 : 0.5
        });
        TweenMax.to(this._arrowElement, value ? 0.1 : 0.3, {
            stroke: value ? Color.RED : Color.DARK_GREY,
            onComplete: () => {
                if (autoRemove) {
                    this.highlight(false);
                }
            }
        });

    }

    public updateStyles() {
        this._arrowSVG.applyStyle({
            cursor: "pointer"
        });
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initArrowSVG() {
        this._arrowSVG = new SVGView(require("ASSETS/images/rounded-arrow.svg"));
        this.addChild(this._arrowSVG);
    }

    private initElements() {
        this._arrowElement = this._arrowSVG.getElementByID("arrow");
        this._hitzone = this._arrowSVG.getElementByID("hitzone");
        this._circle = this._arrowSVG.getElementByID("circle");
        TweenMax.set(this._hitzone, {
            autoAlpha: 0
        })
    }

    private initListeners() {
        this._arrowSVG.view.addEventListener("click", () => this.onClicked());
        this._arrowSVG.view.addEventListener("mouseover", () => this.onMouseOver());
        this._arrowSVG.view.addEventListener("mouseout", () => this.onMouseOut());
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onClicked() {
        this.onClickSignal.dispatch();
    }

    private onMouseOver() {
        this.highlight(true);
        this.onOverSignal.dispatch();
    }

    private onMouseOut() {
        this.highlight(false);
        this.onOutSignal.dispatch();
    }
}
